import React, { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { QUERY_SIMULATION_POLL } from 'client/app/api/gql/queries';
import { useMixPreview } from 'client/app/api/MixPreviewApi';
import { Simulation } from 'client/app/gql';
import { simulationRoutes } from 'client/app/lib/nav/actions';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import MixScreen from 'common/ui/components/simulation-details/mix/MixScreen';

type SimulationForPreview = Pick<
  Simulation,
  'status' | 'id' | 'actionsLayoutFiletreeLink'
>;

type Props<T extends SimulationForPreview> = {
  simulation?: T;
  setIsMixPreviewFullcreen?: (v: boolean) => void;
};

export default function ExampleSimulation<T extends SimulationForPreview>({
  simulation,
  setIsMixPreviewFullcreen,
}: Props<T>) {
  if (!simulation) {
    return null;
  }

  switch (simulation.status) {
    case 'COMPLETED':
      return (
        <Preview
          simulation={simulation}
          setIsMixPreviewFullcreen={setIsMixPreviewFullcreen}
        />
      );
    case 'RUNNING':
    case 'QUEUED':
    case 'RETRYABLE':
      return <PollSimulationUntilComplete simulationId={simulation.id} />;
    case 'FAILED':
    case 'UNKNOWN':
      return <Error simulationId={simulation.id} />;
  }
}

const POLLING_INTERVAL = 2000;
function PollSimulationUntilComplete({ simulationId }: { simulationId: SimulationId }) {
  // we poll a 'light' version of the simulation to reduce how much data we transfer on each poll,
  // then refetch the Protocol once the status changes
  const [getSimulationStatus, { data: simulationData, stopPolling }] = useLazyQuery(
    QUERY_SIMULATION_POLL,
    { pollInterval: POLLING_INTERVAL },
  );

  useEffect(() => {
    getSimulationStatus({ variables: { id: simulationId } }).catch(console.error);
  }, [simulationId, getSimulationStatus]);

  useEffect(() => {
    const simulationStatus = simulationData?.simulation.status;
    if (simulationStatus && simulationStatus !== 'RUNNING') {
      stopPolling();
    }
  }, [simulationId, simulationData?.simulation.status, stopPolling]);

  return (
    <InlinePreview>
      <PreviewSpinner />
    </InlinePreview>
  );
}

function Preview<T extends SimulationForPreview>({
  simulation,
  setIsMixPreviewFullcreen,
}: Props<T>) {
  const { mixPreview } = useMixPreview(simulation ?? undefined);
  return (
    <InlinePreview>
      {mixPreview ? (
        <MixScreen
          mixPreview={mixPreview}
          plateTypes={[]}
          onFullScreenChange={setIsMixPreviewFullcreen}
        />
      ) : (
        <PreviewSpinner />
      )}
    </InlinePreview>
  );
}

function Error({ simulationId }: { simulationId: SimulationId }) {
  const navigation = useNavigation();
  const onClick = () => {
    navigation.navigate(simulationRoutes.openInSimulationDetails, {
      simulationId,
    });
  };
  return (
    <InlinePreview>
      <ErrorWrapper>
        <ErrorOutlineOutlinedIcon htmlColor={Colors.ERROR_MAIN} titleAccess="Error" />
        <ErrorDetail>
          <Typography variant="h2">Simulation Failed</Typography>
          <Button variant="secondary" onClick={onClick}>
            View Simulation
          </Button>
        </ErrorDetail>
      </ErrorWrapper>
    </InlinePreview>
  );
}

const InlinePreview = styled('div')({
  aspectRatio: '16 / 9',
  border: `1px solid ${Colors.GREY_30}`,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
});

const PreviewSpinner = styled(CircularProgress)({
  margin: 'auto',
});

const ErrorWrapper = styled('div')(({ theme: { spacing } }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'row',
  gap: spacing(4),
  alignItems: 'center',
}));

const ErrorDetail = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(3),
  alignItems: 'center',
}));
