import { useQuery } from '@apollo/client';

import { QUERY_PROTOCOL_INSTANCE_BY_SIMULATION } from 'client/app/api/gql/queries';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export default function useSimulationProtocolInstance(simulationId: SimulationId) {
  const snackbar = useSnackbarManager();

  const { data, loading } = useQuery(QUERY_PROTOCOL_INSTANCE_BY_SIMULATION, {
    variables: { simulationId },
    onError: error => snackbar.showError(error.message),
    fetchPolicy: 'network-only',
  });

  return {
    protocolInstanceId: data?.protocolInstances.items?.[0]?.id,
    loading,
  };
}
