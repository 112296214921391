import { State } from 'client/app/apps/policy-library/components/PolicyDialogStateContext';
import { LiquidPolicy } from 'client/app/gql';
import { RULE_NAME_FIELD } from 'common/lib/liquidPolicies';

const NAME_REGEX = /^[A-Za-z0-9,_,-]+$/;

export function makeValidateName({
  liquidPolicies,
}: {
  liquidPolicies: LiquidPolicy[] | undefined;
}) {
  return (newName: string) => {
    const newNameProcessed = newName.trim().toLowerCase();

    const isEmpty = newNameProcessed === '';
    const hasInvalidCharacters = !NAME_REGEX.test(newName);
    const existPolicyName = liquidPolicies?.some(
      policy => policy.name.toLowerCase() === newNameProcessed,
    );

    let error: string | undefined;
    if (isEmpty) {
      error =
        'Policy name cannot be empty. Please, provide a name for your liquid policy.';
    } else if (hasInvalidCharacters) {
      error =
        'Policy name must only contain letters and numbers, with no spaces or special characters.';
    } else if (existPolicyName) {
      error = `Policy with name "${newName}" already exists. Please, provide a unique name for your liquid policy.`;
    }

    return error;
  };
}

export function validateDialogState({ policy, dialog }: State): State['dialog'] {
  let message: string | undefined;

  const invalidName = Boolean(policy.name.error ?? !policy.name.value);

  if (!message && invalidName) {
    message = 'Please input liquid policy name.';
  }

  const invalidDescription = Boolean(
    policy.description.error ?? !policy.description.value,
  );

  if (!message && invalidDescription) {
    message = 'Please input liquid policy description.';
  }

  const { dataTable } = policy.rules;
  const noRules =
    dataTable.data.length === 0 ||
    // Each rule must have a name so there is always 1 column for rule name
    dataTable.schema.fields.length === 1;

  if (!message && noRules) {
    message =
      'Please add at least one rule to the liquid policy. Each rule must have at least one consequence.';
  }

  const existRuleWithEmptyName = dataTable.data.some(row => !row[RULE_NAME_FIELD]);

  if (!message && existRuleWithEmptyName) {
    message = 'Rule name cannot be empty. Please input the name for liquid policy rule.';
  }

  return {
    ...dialog,
    rulesTab: { disabled: invalidName || invalidDescription },
    submitButton: {
      disabled: invalidName || invalidDescription || noRules || existRuleWithEmptyName,
      message,
    },
  };
}
