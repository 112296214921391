import React from 'react';

import { useQuery } from '@apollo/client';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { withRouter } from 'react-router-dom';

import { QUERY_AVAILABLE_ROLES, QUERY_USERS_ROLES } from 'client/app/api/gql/queries';
import { usePermissions } from 'client/app/components/Permissions';
import { UserTable } from 'client/app/components/UserTable/UserTable';
import { userRolesQuery } from 'client/app/gql';
import { UserProfileType, useUserProfile } from 'client/app/hooks/useUserProfile';
import { UserInitialsIcon } from 'common/ui/icons/UserInitialsIcon';

const NOT_ADMIN_COPY =
  'Expecting to see other users here? Contact an admin to update your role.';

const HeadingWithValue = ({ heading, value }: { heading: string; value?: string }) => {
  return (
    <HeadingWithValueWrapper>
      <Typography variant="subtitle1">{heading}</Typography>
      <br />
      <Typography>{value}</Typography>
    </HeadingWithValueWrapper>
  );
};

const ProfileScreen = function () {
  const userProfile = useUserProfile();

  const { data: userData } = useQuery(QUERY_USERS_ROLES);
  const { data: roleData } = useQuery(QUERY_AVAILABLE_ROLES);

  const orgUsers = filterSynthaceUsers(userProfile, userData?.users);

  const { currentUserHasPermission } = usePermissions();
  const hasUserPermissions = currentUserHasPermission('read:users');

  return (
    <Container maxWidth="xs">
      <Stack paddingTop={7} paddingBottom={5} gap={9}>
        <IconContainer>
          <UserInitialsIcon size="large" userDisplayName={userProfile?.displayName} />
        </IconContainer>
        <Stack gap={4}>
          <Stack direction="row" justifyContent="space-between">
            <HeadingWithValue heading="Name" value={userProfile?.displayName} />
            <HeadingWithValue heading="Email" value={userProfile?.email} />
          </Stack>
          <HeadingWithValue
            heading="Organisation"
            value={userProfile?.organisationHumanIdentifier}
          />
        </Stack>
      </Stack>
      <Divider />
      {hasUserPermissions ? (
        <UserTable
          userProfile={userProfile}
          orgUsers={orgUsers}
          availableRoles={roleData?.roles}
        />
      ) : (
        <NonAdminView>
          <Typography variant="caption">{NOT_ADMIN_COPY}</Typography>
        </NonAdminView>
      )}
    </Container>
  );
};

/**
 * Even if user is an admin, we do not want to expose Synthace users
 * to customers. Here we filter Synthace users from view, unless
 * the current user is themself a member of staff.
 * @param currentUser: details of the logged in user
 * @param userData: array of all users scoped to this org
 */
export function filterSynthaceUsers(
  currentUser: Pick<UserProfileType, 'email'> | undefined,
  userData: userRolesQuery['users'] | undefined,
) {
  const isSynthaceUser = currentUser?.email.endsWith('@synthace.com');
  return isSynthaceUser
    ? userData
    : userData?.filter(user => !user.email.endsWith('@synthace.com'));
}

const IconContainer = styled('div')({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
});

const HeadingWithValueWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  gridRow: 'auto auto',
});

const NonAdminView = styled('div')({
  paddingTop: '20px',
  textAlign: 'center',
});

export default withRouter(ProfileScreen);
