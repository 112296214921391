import React, { useRef, useState } from 'react';

import { useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import EmptyProtocolInstanceList from 'client/app/apps/protocols/RecentProtocolInstanceList/ProtocolInstancesDialog/EmptyProtocolInstancesList';
import ProtocolInstancesList from 'client/app/apps/protocols/RecentProtocolInstanceList/ProtocolInstancesDialog/ProtocolInstancesList';
import { QUERY_PROTOCOL_INSTANCES } from 'client/app/apps/protocols/RecentProtocolInstanceList/queryProtocolInstances';
import { protocolInstanceRoutes } from 'client/app/lib/nav/actions';
import Colors from 'common/ui/Colors';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import { EntityCardSkeletonList } from 'common/ui/components/EntityCard';
import IconButton from 'common/ui/components/IconButton';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';
import SearchField from 'common/ui/components/SearchField';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = DialogProps<void> & {
  protocolId: ProtocolId;
};

export function ProtocolInstancesDialog({ isOpen, onClose, protocolId }: Props) {
  const [searchQuery, setSearchQuery] = useState('');
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const { navigate } = useNavigation();

  const variables = {
    protocolId,
    search: searchQuery,
  };

  const protocolsQuery = useQuery(QUERY_PROTOCOL_INSTANCES, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const handleOnProtocolInstanceClick = (id: string) => {
    navigate(protocolInstanceRoutes.editProtocolInstance, {
      id,
    });
  };

  return (
    <StyledDialog
      open={isOpen}
      onClose={() => onClose()}
      maxWidth="lg"
      fullWidth
      TransitionProps={{
        unmountOnExit: true,
      }}
    >
      <HeaderWrapper>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title>
            <Typography variant="h2">Protocol Instances</Typography>
          </Title>
          <IconButton
            icon={<CloseIcon />}
            onClick={() => onClose()}
            color="inherit"
            size="small"
          />
        </Box>
        <HeaderBody>
          <SearchField
            variant="standard"
            label="Search"
            defaultValue={searchQuery}
            onQueryChange={setSearchQuery}
          />
        </HeaderBody>
      </HeaderWrapper>
      <ContainerWithIntersectionBar
        scrollableRef={scrollableContainerRef}
        noHeader
        content={
          <RenderQuery
            query={protocolsQuery}
            renderData={ProtocolInstancesList}
            renderNoData={EmptyProtocolInstanceList}
            loadingComponent={EntityCardSkeletonList}
            additionalNoDataProps={{ searchQuery }}
            additionalDataProps={{ handleOnProtocolInstanceClick }}
            emptyCondition={data => data.protocolInstances.items.length === 0}
          />
        }
        dense
      />
    </StyledDialog>
  );
}

const StyledDialog = styled(Dialog)(({ theme: { spacing } }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: Colors.GREY_5,
    padding: spacing(6),
    height: '100%',
  },
}));

const HeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(6),
}));

const HeaderBody = styled('div')(() => ({
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'space-between',
}));

const Title = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));
