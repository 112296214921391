import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ShortTextIcon from '@mui/icons-material/ShortText';

import { ZOOM_BREAKPOINT } from 'client/app/components/ElementGroup/constants';
import ZoomContext from 'common/ui/components/Workspace/ZoomContext';

type DescriptionContextType = {
  descriptionButtonIcon: React.ReactNode;
  descriptionButtonTitle: string;
  handleDescriptionButtonOnClick: () => void;
  isEditingDescription: boolean;
  showDescription: boolean;
  handleStartEditDescription: () => void;
  handleFinishEditDescription: () => void;
};

export const DescriptionContext = createContext<DescriptionContextType | undefined>(
  undefined,
);

export const useDescriptionContext = () => {
  const context = useContext(DescriptionContext);

  if (context === undefined) {
    throw new Error('useDescriptionContext must be used within a DescriptionProvider');
  }

  return context;
};

type Props = {
  description?: string | undefined;
  isReadonly: boolean;
  hasSelectedElements: boolean;
  onSelect: (event?: React.PointerEvent) => void;
};

export const DescriptionProvider: FC<PropsWithChildren<Props>> = ({
  children,
  description,
  isReadonly,
  hasSelectedElements,
  onSelect,
}) => {
  const zoom = useContext(ZoomContext);
  const zoomedOut = zoom <= ZOOM_BREAKPOINT;
  const hasDescription = !!description;
  const [showDescription, setShowDescription] = useState(false);
  const [isEditingDescription, setEditingDescription] = useState(false);

  useEffect(() => {
    if (!hasSelectedElements) setShowDescription(false);
  }, [hasSelectedElements]);

  const handleStartEditDescription = () => {
    setShowDescription(true);
    if (!isReadonly) {
      onSelect();
      setEditingDescription(true);
    }
  };

  const handleFinishEditDescription = () => {
    setEditingDescription(false);
  };

  const handleDescriptionButtonOnClick = () => {
    if (hasDescription) {
      setShowDescription(!showDescription);
      onSelect();
      return;
    }
    if (!isReadonly) {
      handleStartEditDescription();
    }
  };

  const descriptionButtonIcon = useMemo(() => {
    if (hasDescription && showDescription) return <ExpandLess />;
    if (hasDescription && !showDescription) return <ExpandMore />;
    if (!isReadonly) return <ShortTextIcon />;
    return undefined;
  }, [hasDescription, isReadonly, showDescription]);

  const descriptionButtonTitle = useMemo(() => {
    if (zoomedOut) return '';
    if (hasDescription && showDescription) return 'Hide description';
    if (hasDescription && !showDescription) return 'Show description';
    if (!isReadonly) return 'Add description';
    return '';
  }, [hasDescription, isReadonly, showDescription, zoomedOut]);

  const state = {
    descriptionButtonIcon,
    descriptionButtonTitle,
    handleDescriptionButtonOnClick,
    showDescription,
    isEditingDescription,
    handleStartEditDescription,
    handleFinishEditDescription,
  };

  return (
    <DescriptionContext.Provider value={state}>{children}</DescriptionContext.Provider>
  );
};
