import { BreadcrumbsStep } from 'client/app/apps/landing-page/status/types';
import { BreadcrumbsEntityEnum } from 'client/app/gql';

const STATUS_BREADCRUMB_TYPE_DATA_HEAP_TRACKING_MAP = {
  [BreadcrumbsEntityEnum.CHERRY_PICKER]: 'cherry-picker',
  [BreadcrumbsEntityEnum.DATASET_DERIVATION]: 'dataset-derivation',
  [BreadcrumbsEntityEnum.EXECUTION]: 'execution',
  [BreadcrumbsEntityEnum.EXPERIMENT]: 'experiment',
  [BreadcrumbsEntityEnum.FORM]: 'form',
  [BreadcrumbsEntityEnum.SIMULATION]: 'simulation',
  [BreadcrumbsEntityEnum.VIS]: 'vis',
  [BreadcrumbsEntityEnum.VISUALISATION]: 'visualisation',
  [BreadcrumbsEntityEnum.WORKFLOW]: 'workflow',
  [BreadcrumbsEntityEnum.PROTOCOL_INSTANCE]: 'protocol-instance',
  [BreadcrumbsEntityEnum.PROTOCOL]: 'protocol',
  [BreadcrumbsEntityEnum.WORK_TREE]: 'worktree',
} as const;

function useStatusBreadcrumbDataHeapTracking(breadcrumb: BreadcrumbsStep) {
  return `status-breadcrumbs-${
    STATUS_BREADCRUMB_TYPE_DATA_HEAP_TRACKING_MAP[breadcrumb.entity]
  }-entity-item`;
}

export default useStatusBreadcrumbDataHeapTracking;
