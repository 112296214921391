import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import MuiHelpIcon from '@mui/icons-material/HelpOutline';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import MuiTab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography, { typographyClasses } from '@mui/material/Typography';

import {
  State,
  usePolicyDialogState,
} from 'client/app/apps/policy-library/components/PolicyDialogStateContext';
import PropertiesTab from 'client/app/apps/policy-library/components/PropertiesTab';
import RulesTab from 'client/app/apps/policy-library/components/RulesTab';
import useAddPolicy from 'client/app/apps/policy-library/components/useAddPolicy.hook';
import useDownloadTemplate from 'client/app/apps/policy-library/components/useDownloadTemplate';
import useUpdatePolicy from 'client/app/apps/policy-library/components/useUpdatePolicy.hook';
import useXlsxFileRead from 'client/app/apps/policy-library/components/useXlsxFileRead';
import Colors from 'common/ui/Colors';
import Popover from 'common/ui/components/Popover';
import Tooltip from 'common/ui/components/Tooltip';

type Props = {
  /**
   * Callback to handle file encoded as base64.
   *
   * @param fileData complete DataURL of the file including MIME type and base64 encoding
   *
   * e.g. data:{mime-type};base64,{base64-encoded-data}
   */
  onReadXlsx: (fileData: string) => void;
};
export default function PolicyEditorDialog({ onReadXlsx }: Props) {
  const [{ dialog, policy }, dispatch] = usePolicyDialogState();

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    dispatch({
      type: 'set_active_tab',
      payload: newValue,
    });
  };

  const closeDialog = () => dispatch({ type: 'close_dialog' });
  const resetState = () => dispatch({ type: 'reset_state' });

  const { dialogCopies, loading, submit } = useDialogActions({
    policy,
    dialog,
    onSuccess() {
      resetState();
      closeDialog();
    },
  });

  const { fileInputKey, ref, selectFile, openFile } = useXlsxFileRead(fileData => {
    closeDialog();
    onReadXlsx(fileData);
  });
  const downloadTemplate = useDownloadTemplate();

  return (
    <Dialog open={dialog.open} fullWidth maxWidth="lg" onClose={closeDialog}>
      <DialogTitle>
        <Typography variant="h2">{dialogCopies.title}</Typography>
        <IconButton onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack gap={5} px={8} py={6}>
          <Typography variant="h6" color="textSecondary">
            Define the properties and rules of your new policy.
          </Typography>
          <Tabs
            value={dialog.activeTab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              id="tab-0"
              aria-controls="tabpanel-0"
              label={
                <Typography variant="body2" fontWeight={500}>
                  Properties
                </Typography>
              }
            />
            <Tab
              id="tab-1"
              aria-controls="tabpanel-1"
              label={
                <Typography variant="body2" fontWeight={500}>
                  Rules
                </Typography>
              }
              disabled={dialog.rulesTab.disabled}
            />
          </Tabs>
          <TabContent value={dialog.activeTab} index={0}>
            <PropertiesTab />
          </TabContent>
          <TabContent value={dialog.activeTab} index={1}>
            <RulesTab />
          </TabContent>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" gap={2}>
          <Button
            variant="text"
            color="inherit"
            endIcon={
              <Popover title={<BulkUploadHelpText />}>
                <HelpIcon />
              </Popover>
            }
            onClick={selectFile}
          >
            <Typography variant="button" color="textSecondary" fontSize={14}>
              Upload from excel
            </Typography>
          </Button>
          <Divider orientation="vertical" sx={{ height: 24 }} />
          <Button variant="text" color="inherit" onClick={downloadTemplate}>
            <Typography
              variant="button"
              color="textSecondary"
              fontSize={14}
              textTransform="none"
            >
              Download template
            </Typography>
          </Button>
          <input key={fileInputKey} ref={ref} type="file" hidden onChange={openFile} />
        </Stack>
        <Tooltip
          title={
            dialog.submitButton.message ? (
              <Typography variant="caption">{dialog.submitButton.message}</Typography>
            ) : null
          }
          placement="top"
        >
          <span>
            <Button
              variant="text"
              color="primary"
              disabled={dialog.submitButton.disabled || loading}
              onClick={() => submit(policy)}
            >
              <Typography variant="button" fontSize={14}>
                {dialogCopies.submit}
              </Typography>
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
}

function useDialogActions({
  policy,
  dialog,
  onSuccess,
}: State & { onSuccess: () => void }) {
  const { addPolicy, loading: creating } = useAddPolicy({ onSuccess });
  const { updatePolicy, loading: updating } = useUpdatePolicy({ onSuccess });

  switch (dialog.mode) {
    case 'create':
      return {
        dialogCopies: {
          title: 'Add a new liquid policy',
          submit: 'Add policy',
        },
        loading: creating,
        submit: addPolicy,
      };
    case 'edit':
      return {
        dialogCopies: {
          title: `Edit liquid policy: "${policy.name.value}"`,
          submit: 'Save',
        },
        loading: updating,
        submit: updatePolicy,
      };
    default:
      throw new Error('Unknown mode');
  }
}

const TabContent = ({
  children,
  value,
  index,
}: React.PropsWithChildren<{ value: number; index: number }>) => (
  <TabContentMain
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
    role="tabpanel"
    hidden={value !== index}
  >
    {value === index && children}
  </TabContentMain>
);

const BulkUploadHelpText = () => (
  <Stack gap={2} p={2}>
    <Typography variant="caption" color="textPrimary" component="p">
      Bulk upload liquid policies using an XLSX file based on the provided template.
    </Typography>
    <Typography variant="caption" color="textPrimary" component="p">
      The template can be downloaded by clicking the &quot;Download template&quot; button
      on the right.
    </Typography>
  </Stack>
);

const DialogTitle = styled(MuiDialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: Colors.GREY_5,
  borderBottom: `1px solid ${Colors.GREY_30}`,
});

const DialogContent = styled(MuiDialogContent)({
  backgroundColor: Colors.GREY_5,
  padding: 0,
});

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  justifyContent: 'space-between',
  padding: theme.spacing(3, 6, 5),
  backgroundColor: Colors.GREY_5,
}));

const Tab = styled(MuiTab)(({ theme }) => ({
  [`& .${typographyClasses.root}:after`]: {
    content: '"*"',
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1),
  },
}));

const TabContentMain = styled('main')(({ theme }) => ({
  height: 600,
  paddingTop: theme.spacing(3),
}));

const HelpIcon = styled(MuiHelpIcon)(({ theme }) => ({
  fontSize: 18,
  color: theme.palette.text.secondary,
}));
