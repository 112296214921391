import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import Fab from '@mui/material/Fab';
import MuiMenu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import { paperClasses } from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import useXlsxFileRead from 'client/app/apps/policy-library/components/useXlsxFileRead';
import Colors from 'common/ui/Colors';

type Props = {
  onCreate: () => void;
  /**
   * Callback to handle file encoded as base64.
   *
   * @param fileData complete DataURL of the file including MIME type and base64 encoding
   *
   * e.g. data:{mime-type};base64,{base64-encoded-data}
   */
  onReadXlsx: (fileData: string) => void;
};

function FabMenu({ onCreate, onReadXlsx }: Props) {
  const { fileInputKey, ref, selectFile, openFile } = useXlsxFileRead(onReadXlsx);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // Handle opening the menu
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing the menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <FabFixed color="secondary" onClick={handleClick}>
        <AddIconWithRotate open={open} />
      </FabFixed>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem disableRipple>
          <FabLabel variant="h4">Add a new liquid policy</FabLabel>
          <Fab
            color="secondary"
            onClick={() => {
              handleClose();
              onCreate();
            }}
          >
            <AddIcon />
          </Fab>
        </MenuItem>
        <MenuItem disableRipple>
          <FabLabel variant="h4">Bulk upload from Excel</FabLabel>
          <Fab
            color="secondary"
            onClick={() => {
              handleClose();
              selectFile();
            }}
          >
            <UploadIcon />
          </Fab>
        </MenuItem>
      </Menu>
      <input key={fileInputKey} ref={ref} type="file" hidden onChange={openFile} />
    </>
  );
}

export default FabMenu;

const FabFixed = styled(Fab)({
  position: 'absolute',
  bottom: '32px',
  right: '32px',
});

const Menu = styled(MuiMenu)({
  [`.${paperClasses.root}`]: {
    background: 'transparent',
    boxShadow: 'none',
  },
});

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  margin: theme.spacing(4),
  padding: theme.spacing(0, 2, 0),
  width: 250,
  cursor: 'default',

  background: 'transparent',
  '&:hover,&:focus': {
    background: 'transparent',
  },
}));

const AddIconWithRotate = styled(AddIcon, {
  shouldForwardProp: prop => prop !== 'open',
})<{
  open: boolean;
}>(({ open }) => ({
  transition: 'transform 100ms',
  transform: open ? 'rotate(-45deg)' : undefined,
}));

const FabLabel = styled(Typography)({
  backgroundColor: Colors.GREY_5,
});
