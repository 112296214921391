import React from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';

import { TopBar } from 'client/app/components/TopBar';
import { protocolInstanceRoutes } from 'client/app/lib/nav/actions';
import RouteButton from 'common/ui/components/navigation/RouteButton';

type Props = {
  protocolInstanceId: ProtocolInstanceId;
  author?: string;
};

export function ProtocolInstanceReadOnlyBar({ protocolInstanceId, author }: Props) {
  return (
    <TopBar>
      <InfoOutlined color="info" />
      <Typography variant="h6" color="textPrimary">
        This protocol {author ? `by ${author} ` : ''}is in <b>read-only</b> mode.
      </Typography>
      <RouteButton
        label="Duplicate"
        route={protocolInstanceRoutes.copyProtocolInstance}
        routeParam={{ id: protocolInstanceId }}
        variant="contained"
        color="primary"
        startIcon={<ContentCopyIcon />}
      />
    </TopBar>
  );
}
