import React, { PropsWithChildren } from 'react';

import { styled } from '@mui/material/styles';

import sanitizeLink from 'client/app/lib/sanitizeLink';

type Props = {
  href: string;
};

export function MenuItemExternalLink({ href, children }: PropsWithChildren<Props>) {
  return (
    <StyledLink target="_blank" rel="noopener noreferrer" href={sanitizeLink(href)}>
      {children}
    </StyledLink>
  );
}

export const StyledLink = styled('a')({
  color: 'inherit',
  textDecoration: 'none',
});
