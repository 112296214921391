import React from 'react';

import StepContents from 'client/app/apps/protocols/StepCard/StepContents';
import { ProtocolStep } from 'common/types/Protocol';

type Props = {
  step: ProtocolStep;
  onDelete?: (contentIndex: number) => void;
};

export default function OutputStepContents(props: Props) {
  const { step, onDelete } = props;

  return (
    <StepContents
      items={step.outputs}
      emptyMessage="Select an output from the elements in the workflow."
      onDelete={onDelete}
    />
  );
}
