import React from 'react';

import ProtocolInstancePanel from 'client/app/apps/protocols/ProtocolInstancePanel/ProtocolInstancePanel';
import ProtocolItemList from 'client/app/apps/protocols/ProtocolInstancePanel/ProtocolItemList';
import { useElementNamePopover } from 'client/app/apps/workflow-builder/panels/element-instance-panel/hooks';
import { Parameter } from 'common/types/bundle';
import { ProtocolStep } from 'common/types/Protocol';
import {
  byElementInstanceId,
  getElementParameterName,
  Schema,
} from 'common/types/schema';

type Props = {
  schema?: Schema;
  protocolSteps: ProtocolStep[];
  activeStepId: string;
  elementInstanceId: string;
  elementInstanceName: string;
  inputs: { parameter: Parameter; value: any }[];
  onChange: (param: Parameter, value: any, checked: boolean) => void;
  onClose: () => void;
};

const ProtocolElementInputInstancePanel = ({
  schema,
  protocolSteps,
  activeStepId,
  elementInstanceId,
  elementInstanceName,
  inputs,
  onChange,
  onClose,
}: Props) => {
  const { elementNamePopover, ...elementNamePopoverEvents } =
    useElementNamePopover(elementInstanceName);

  const selectedInputs: Record<string, ProtocolStep[]> = {};
  const inputsForElement =
    schema?.inputs?.filter(byElementInstanceId(elementInstanceId)) ?? [];
  protocolSteps.forEach(step => {
    step.inputs.forEach(protocolInput => {
      const schemaInput = inputsForElement.find(v => v.id === protocolInput.id);
      if (schemaInput) {
        const pName = getElementParameterName(schemaInput.path);
        if (!pName) {
          throw 'failed to get parameter name';
        }
        if (!selectedInputs[pName]) {
          selectedInputs[pName] = [];
        }
        selectedInputs[pName].push(step);
      }
    });
  });

  return (
    <ProtocolInstancePanel
      title={elementInstanceName}
      onClose={onClose}
      {...elementNamePopoverEvents}
    >
      <ProtocolItemList
        elementId={elementInstanceId}
        parameters={inputs}
        selections={selectedInputs}
        activeStepId={activeStepId}
        mode="input"
        onChange={onChange}
      />
      {elementNamePopover}
    </ProtocolInstancePanel>
  );
};

export default ProtocolElementInputInstancePanel;
