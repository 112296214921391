import React, { useCallback } from 'react';

import KebabIcon from '@mui/icons-material/MoreVertOutlined';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { DeleteSimulation } from 'client/app/apps/workflow-builder/panels/simulations/simulation-card/menu-items/DeleteSimulation';
import { RenameSimulation } from 'client/app/apps/workflow-builder/panels/simulations/simulation-card/menu-items/RenameSimulation';
import { RequestHelp } from 'client/app/apps/workflow-builder/panels/simulations/simulation-card/menu-items/RequestHelp';
import { ShareWorkflowItem } from 'client/app/apps/workflow-builder/panels/simulations/simulation-card/menu-items/ShareWorkflow';
import {
  isSimulationPlaceholder,
  SimulationForWorkflow,
  SimulationPlaceholder,
} from 'client/app/apps/workflow-builder/panels/simulations/useSimulations';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import {
  graceRoutes,
  simulationRoutes,
  workflowRoutes,
} from 'client/app/lib/nav/actions';
import { ScreenRegistry } from 'client/app/registry';
import IconButton from 'common/ui/components/IconButton';
import { MenuItemExternalLink } from 'common/ui/components/Menu/MenuItemExternalLink';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import { SimulationIcon } from 'common/ui/icons';
import { GraceIcon } from 'common/ui/icons/GraceIcon';
import { SplunkIcon } from 'common/ui/icons/SplunkIcon';
import { TraceIcon } from 'common/ui/icons/TraceIcon';
import { WorkflowIcon } from 'common/ui/icons/Workflow';

type MoreActionMenuProps = {
  simulation: SimulationForWorkflow;
  menuAnchorEl: HTMLElement | null;
  handleClose: () => void;
  workflowId: WorkflowId;
  showViewSimulation?: boolean;
};

function MoreActions({
  simulation,
  workflowId,
  showViewSimulation,
}: {
  simulation: SimulationForWorkflow | SimulationPlaceholder;
  workflowId: WorkflowId;
  showViewSimulation?: boolean;
}) {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenuItem = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  if (isSimulationPlaceholder(simulation)) {
    return <IconButton size="small" icon={<KebabIcon />} disabled title="More Actions" />;
  }
  return (
    <>
      <IconButton
        size="small"
        icon={<KebabIcon />}
        onClick={handleMenuItem}
        title="More Actions"
      />
      <MoreActionsMenu
        simulation={simulation}
        menuAnchorEl={menuAnchorEl}
        handleClose={handleClose}
        workflowId={workflowId}
        showViewSimulation={showViewSimulation}
      />
    </>
  );
}

function MoreActionsMenu(props: MoreActionMenuProps) {
  const { simulation, menuAnchorEl, handleClose, workflowId, showViewSimulation } = props;

  const logViewSnapshot = useCallback(() => {
    // Currently we only log when the user left-clicks but they could right-click
    // and open in a new tab.
    logEvent('click-view-snapshot', ScreenRegistry.WORKFLOW);
  }, []);

  const simulationPath = simulationRoutes.openInSimulationDetails.getPath({
    simulationId: simulation.id,
  });

  const snapshotPath = workflowRoutes.openInWorkflowBuilder.getPath({
    workflowId: simulation.workflow.id,
  });

  const editInGracePath = graceRoutes.openSimulationInGrace.getPath({
    simulationId: simulation.id,
  });

  const userProfile = useUserProfile();
  const isSupport = userProfile?.isSupport ?? false;

  const synthaceEmployeeOnlyItems = userProfile?.isSynthaceEmployee
    ? [
        <StyledDivider key="divider" />,
        isSupport && (
          <ShareWorkflowItem simulation={simulation} closeMenu={handleClose} />
        ),
        simulation.traceUrl && (
          <MenuItemExternalLink key="trace" href={simulation.traceUrl}>
            <MenuItemWithIcon icon={<TraceIcon />} onClick={handleClose} text="Trace" />
          </MenuItemExternalLink>
        ),
        simulation.logsUrl && (
          <MenuItemExternalLink key="logs" href={simulation.logsUrl}>
            <MenuItemWithIcon icon={<SplunkIcon />} onClick={handleClose} text="Logs" />
          </MenuItemExternalLink>
        ),
        simulation.id && (
          <MenuItemExternalLink key="grace" href={editInGracePath}>
            <MenuItemWithIcon
              icon={<GraceIcon />}
              onClick={handleClose}
              text="Edit with Grace"
            />
          </MenuItemExternalLink>
        ),
      ]
    : null;

  return (
    <Menu
      anchorEl={menuAnchorEl}
      keepMounted
      open={!!menuAnchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {showViewSimulation && (
        <StyledLink to={simulationPath}>
          <MenuItemWithIcon icon={<SimulationIcon />} text="View Simulation" />
        </StyledLink>
      )}

      <StyledLink to={snapshotPath} onMouseDown={logViewSnapshot}>
        <MenuItemWithIcon icon={<WorkflowIcon />} text="View Snapshot" />
      </StyledLink>

      {!isSupport && <RequestHelp simulation={simulation} closeMenu={handleClose} />}

      <DeleteSimulation
        simulation={simulation}
        workflowId={workflowId}
        closeMenu={handleClose}
      />

      <RenameSimulation simulation={simulation} closeMenu={handleClose} />

      {synthaceEmployeeOnlyItems}
    </Menu>
  );
}

const StyledLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'none',
});

const StyledDivider = styled(Divider)(({ theme: { spacing } }) => ({
  margin: spacing(3, 0),
}));

export default MoreActions;
