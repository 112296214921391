import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { MUTATION_UPLOAD_POLICIES_XLSX } from 'client/app/api/gql/mutations';
import { QUERY_LIQUID_POLICIES } from 'client/app/api/gql/queries';
import { pluralize } from 'common/lib/format';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export default function useXlsxFileUpload({ onSuccess }: { onSuccess: () => void }) {
  const snackbar = useSnackbarManager();
  const [uploadXlsxFile, { loading }] = useMutation(MUTATION_UPLOAD_POLICIES_XLSX, {
    onCompleted({ uploadPoliciesXlsx }) {
      let message: string = '';
      let showMessage = snackbar.showSuccess;
      if (uploadPoliciesXlsx?.uploadCount) {
        message += `${pluralize(
          uploadPoliciesXlsx.uploadCount,
          'new policy',
          'new policies',
        )} added. `;
      }
      if (uploadPoliciesXlsx?.skipCount) {
        message += `${pluralize(
          uploadPoliciesXlsx.skipCount,
          'policy',
          'policies',
        )} skipped.`;
        showMessage = snackbar.showWarning;
      }
      if (uploadPoliciesXlsx?.errorCount) {
        message += `${pluralize(
          uploadPoliciesXlsx.errorCount,
          'policy',
          'policies',
        )} could not be uploaded due to errors.`;
        showMessage = snackbar.showWarning;
      }
      showMessage(message);
      onSuccess();
    },
    onError(error) {
      snackbar.showError(error.message);
    },
    refetchQueries: [QUERY_LIQUID_POLICIES],
  });
  const uploadPoliciesXlsx = useCallback(
    async (base64: string) => {
      await uploadXlsxFile({ variables: { base64 } });
    },
    [uploadXlsxFile],
  );
  return {
    loading,
    uploadPoliciesXlsx,
  };
}
