import React from 'react';

import {
  NoOutputStepPreview,
  OutputStepPreview,
  OutputStepPreviewSkeleton,
} from 'client/app/apps/protocols/OutputStepPreview';
import { ErrorsResponse } from 'client/app/apps/protocols/OutputStepResponse/ErrorsResponse';
import { StepResponseById } from 'client/app/apps/protocols/OutputStepResponse/useStepsReponse';

type Props = {
  loading: boolean;
  selectedStepId?: string;
  stepsResponse: StepResponseById;
  ignoreErrors: boolean;
  handleSelectStep: (stepId: string) => void;
  clearSimulation: () => void;
};

export function OutputStepResponse({
  loading,
  selectedStepId = '',
  stepsResponse,
  ignoreErrors,
  handleSelectStep,
  clearSimulation,
}: Props) {
  const {
    [selectedStepId]: selectedResponse = { errors: [], warnings: [] },
    ...otherStepResponses
  } = stepsResponse;
  const anyErrors = Object.values(stepsResponse).some(v => v.errors.length > 0);
  const anyWarnings = Object.values(stepsResponse).some(v => v.warnings.length > 0);

  const isLoading = loading || !selectedResponse;
  if (isLoading) return <OutputStepPreviewSkeleton />;

  const showErrors = (anyErrors || anyWarnings) && !ignoreErrors;
  if (showErrors) {
    return (
      <ErrorsResponse
        selectedResponse={selectedResponse}
        otherResponses={otherStepResponses as StepResponseById}
        handleSelectStep={handleSelectStep}
        onClearSimulationError={clearSimulation}
      />
    );
  }

  if (selectedResponse.hasPreview) {
    return <OutputStepPreview />;
  }
  return <NoOutputStepPreview />;
}
