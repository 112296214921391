import React, { useContext, useMemo, useState } from 'react';

import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShortTextIcon from '@mui/icons-material/ShortText';
import TitleIcon from '@mui/icons-material/Title';
import Menu from '@mui/material/Menu';
import cx from 'classnames';
import useResizeObserver from 'use-resize-observer';

import {
  MIN_DESCRIPTION_WIDTH,
  ZOOM_BREAKPOINT,
} from 'client/app/components/ElementGroup/constants';
import {
  DescriptionButton,
  ElementGroupDescription,
  useDescriptionContext,
} from 'client/app/components/ElementGroup/Description';
import ElementGroupBackground from 'client/app/components/ElementGroup/ElementGroupBackground';
import ElementGroupName from 'client/app/components/ElementGroup/ElementGroupName';
import useGroupMovement from 'client/app/components/ElementGroup/useGroupMovement';
import useGroupResize, {
  ResizeDelta,
} from 'client/app/components/ElementGroup/useGroupResize';
import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';
import stopPropagation from 'common/lib/stopPropagation';
import { Group } from 'common/types/bundle';
import { Position2d } from 'common/types/Position';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import ZoomContext from 'common/ui/components/Workspace/ZoomContext';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { usePopover } from 'common/ui/hooks/usePopover';
import { UngroupIcon } from 'common/ui/icons/UngroupIcon';

type Props = {
  group: Group;
  isSelected: boolean;
  isReadonly: boolean;
  isDropTarget: boolean;
  dragDelta: Position2d | null;
  onSelect: (group: Group, event?: React.PointerEvent) => void;
  zIndex?: number;
};

const ElementGroup = React.memo(
  ({
    group,
    isSelected,
    isReadonly,
    isDropTarget,
    dragDelta,
    onSelect,
    zIndex,
  }: Props) => {
    const zoom = useContext(ZoomContext);
    const {
      descriptionButtonIcon,
      descriptionButtonTitle,
      handleDescriptionButtonOnClick,
      showDescription,
      isEditingDescription,
      handleStartEditDescription,
      handleFinishEditDescription,
    } = useDescriptionContext();
    const zoomedOut = zoom <= ZOOM_BREAKPOINT;
    const styles = useStyles({ zoomedOut });

    const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
      usePopover();

    const dispatch = useWorkflowBuilderDispatch();

    const { onPointerDown: startMoving } = useGroupMovement(group);
    const { onPointerDown: startResizing, resizeDelta } = useGroupResize(
      group,
      isSelected,
    );

    const groupHeader = useResizeObserver<HTMLElement>({ box: 'border-box' });
    const groupFooter = useResizeObserver<HTMLElement>({ box: 'border-box' });

    const position = useGroupPosition(
      group,
      resizeDelta,
      groupHeader.height,
      groupFooter.height,
      dragDelta,
      zoom,
    );

    const [isEditingName, setIsEditingName] = useState(false);
    const onRename = () => {
      onHidePopover();
      onSelect(group);
      setIsEditingName(true);
    };

    const isGroupHighlighted = isSelected || isDropTarget;
    const hasElements = group.elementIds.length > 0;

    const handleHeaderOnPointerDown = (event: any) => {
      onSelect(group, event);
      if (!isReadonly) startMoving(event);
    };

    const onDelete = () => {
      dispatch({ type: 'deleteElementGroup', payload: group.id });
      onHidePopover();
    };
    const onResizeToFit = () => {
      onHidePopover();
      dispatch({
        type: 'resizeElementGroupToFit',
        payload: group.id,
      });
    };

    return (
      <>
        <main
          className={cx(styles.frame, {
            [styles.selected]: isGroupHighlighted,
          })}
          style={{ ...position, zIndex }}
        >
          <header onPointerDown={handleHeaderOnPointerDown} ref={groupHeader.ref}>
            <ElementGroupName
              groupId={group.id}
              groupName={group.name}
              isGroupHighlighted={isGroupHighlighted}
              isReadonly={isReadonly}
              focus={isEditingName}
              onBlur={() => setIsEditingName(false)}
              zoomedOut={zoomedOut}
            />
            {!isReadonly && !zoomedOut && (
              <>
                <IconButton
                  onClick={onShowPopover}
                  size="xsmall"
                  icon={<MoreVertIcon />}
                  onPointerDown={stopPropagation}
                />
                <Menu
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  anchorEl={popoverAnchorElement}
                  open={isPopoverOpen}
                  onClose={onHidePopover}
                  onPointerDown={stopPropagation}
                >
                  <MenuItemWithIcon
                    text="Rename"
                    icon={<TitleIcon />}
                    onClick={onRename}
                  />
                  <MenuItemWithIcon
                    text={group.Meta.description ? 'Edit description' : 'Add description'}
                    icon={<ShortTextIcon />}
                    onClick={() => {
                      handleStartEditDescription();
                      onHidePopover();
                    }}
                  />
                  <MenuItemWithIcon
                    text={hasElements ? 'Ungroup elements' : 'Delete group'}
                    icon={hasElements ? <UngroupIcon /> : <DeleteIcon />}
                    onClick={onDelete}
                  />
                  <MenuItemWithIcon
                    text="Resize to fit"
                    icon={<AspectRatioIcon />}
                    onClick={onResizeToFit}
                    disabled={group.elementIds.length === 0}
                  />
                </Menu>
              </>
            )}
          </header>
          <footer ref={groupFooter.ref}>
            <DescriptionButton
              icon={descriptionButtonIcon}
              onClick={handleDescriptionButtonOnClick}
            >
              {descriptionButtonTitle}
            </DescriptionButton>
            <ElementGroupDescription
              // The menu won't let us auto-focus anything outside it while it's open
              canAutofocus={!isPopoverOpen}
              onFinishedEditing={handleFinishEditDescription}
              onStartEditing={handleStartEditDescription}
              group={group}
              editing={isEditingDescription}
              show={(group.Meta.description && showDescription) || isEditingDescription}
              shouldResize={(groupFooter.width ?? 0) < MIN_DESCRIPTION_WIDTH}
              zIndex={zIndex}
            />
          </footer>
        </main>
        {/* The background is a separate element to let it be placed below the elements and connectors in z-order */}
        <ElementGroupBackground
          position={position}
          isSelected={isSelected}
          onPointerDown={startResizing}
          zIndex={zIndex}
        />
      </>
    );
  },
);

function useGroupPosition(
  { Meta: { x, y, width, height } }: Group,
  resizeDelta: ResizeDelta,
  headerHeight: number = 0,
  footerHeight: number = 0,
  dragDelta: Position2d | null,
  zoom: number,
) {
  const left = resizeDelta.x + (dragDelta ? x + dragDelta.x : x);
  const top = resizeDelta.y + (dragDelta ? y + dragDelta.y : y);

  const offsetTop = top - headerHeight;
  const scaledWidth = (width + resizeDelta.width) * zoom;
  const scaledHeight = (height + resizeDelta.height) * zoom + headerHeight + footerHeight;

  return useMemo(
    () => ({
      left: `${left}px`,
      top: `${offsetTop}px`,
      width: `${scaledWidth}px`,
      height: `${scaledHeight}px`,
      transform: `scale(${1 / zoom})`,
      transformOrigin: `left ${headerHeight}px`,
    }),
    [headerHeight, left, offsetTop, scaledHeight, scaledWidth, zoom],
  );
}

const useStyles = makeStylesHook<string, { zoomedOut: boolean }>(
  ({ palette, spacing }) => ({
    frame: {
      border: `1px solid rgba(0,0,0,.23)`,
      position: 'absolute',
      borderRadius: '4px',
      pointerEvents: 'none',
      boxShadow: '0px 2px 3px rgba(0, 0, 0, .1)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '& > header': {
        borderBottom: `1px solid ${Colors.GREY_40}`,
        background: Colors.GREY_20,
        display: 'flex',
        gap: spacing(4),
        padding: ({ zoomedOut }) => (zoomedOut ? spacing(1, 2) : spacing(2, 3)),
        borderRadius: '3px 3px 0 0',
        pointerEvents: 'all',
        justifyContent: 'space-between',
        alignItems: 'center',
        mixBlendMode: 'normal',
        cursor: 'grab',
        '&:active': {
          cursor: 'grabbing',
        },
      },
      '& > footer': {
        display: 'flex',
        justifyContent: 'end',
        pointerEvents: 'all',
        position: 'relative',
      },
    },
    selected: {
      borderColor: palette.primary.main,
      outline: `2px solid ${palette.primary.main}`,
    },
  }),
);

export default ElementGroup;
