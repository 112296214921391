import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import moment from 'moment';

import { useFetchGraphQLElementSet } from 'client/app/api/ElementsApi';
import { GraphQLWorkflow } from 'client/app/api/gql/utils';
import * as WorkflowsApi from 'client/app/api/WorkflowsApi';
import ControlOverlay from 'client/app/apps/workflow-builder/ControlOverlay';
import InvalidWorkflowConfigDialog from 'client/app/apps/workflow-builder/dialogs/InvalidWorkflowConfigDialog';
import ElementsCascadeTracker from 'client/app/apps/workflow-builder/ElementsCascadeTracker';
import { useCopyPasteIntoBuilder } from 'client/app/apps/workflow-builder/lib/copyPaste';
import { useDragAndDrop } from 'client/app/apps/workflow-builder/lib/dragAndDropElementsHelper';
import isWorkflowReadonly from 'client/app/apps/workflow-builder/lib/isWorkflowReadonly';
import {
  buildWorkflowBuilderStateBundle,
  deserialiseWorkflowResponse,
  doPreventDefault,
  equivalentBundles,
  removeUnsavedDataFromServerBundle,
  useCreateElementInstance,
  useManualDeviceDefault,
  useValidateWorkflowAndWarnUser,
} from 'client/app/apps/workflow-builder/lib/workflowUtils';
import { PanelContent } from 'client/app/apps/workflow-builder/panels/Panel';
import useSimulations, {
  SIMULATION_PLACEHOLDER_ID,
} from 'client/app/apps/workflow-builder/panels/simulations/useSimulations';
import { ElementBranchSwitcherResults } from 'client/app/apps/workflow-builder/panels/switch-element-set/SwitchElementBranch';
import TimeLine from 'client/app/apps/workflow-builder/TimeLine';
import { WorkflowTopBar } from 'client/app/apps/workflow-builder/TopBar/TopBar';
import WorkflowBuilderStatus from 'client/app/apps/workflow-builder/WorkflowBuilderStatus';
import ElementPlumber from 'client/app/components/ElementPlumber/ElementPlumber';
import { useLaunchExampleWorkflowsDialogInBuilder } from 'client/app/components/ExampleWorkflows/exampleWorkflowsUtils';
import RouteLeavingGuard from 'client/app/components/RouteLeavingGuard';
import UIErrorBox from 'client/app/components/UIErrorBox';
import {
  ArrayElement,
  ElementSetQuery,
  simulationsForWorkflowQuery,
} from 'client/app/gql';
import useEntityConflictErrorDialog from 'client/app/hooks/useEntityConflictErrorDialog';
import { doeTemplateRoutes } from 'client/app/lib/nav/actions';
import ParameterStateContextProvider, {
  ParameterStateContext,
} from 'client/app/lib/rules/elementConfiguration/ParameterStateContext';
import { SuccessfullySavedWorkflow } from 'client/app/lib/workflow/SuccessfullySavedWorkflow';
import { ScreenRegistry } from 'client/app/registry';
import AutocompleteParameterValuesContextProvider from 'client/app/state/AutocompleteParameterValuesContext';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import doNothing from 'common/lib/doNothing';
import { getErrorMessage } from 'common/lib/graphQLErrors';
import {
  BundleParameters,
  Connection,
  CoreError,
  EditorType,
  ElementContextMap,
  ElementInstance,
  Factors,
  Group,
  Stage,
  TemplateWorkflow,
  WorkflowConfig,
} from 'common/types/bundle';
import { configHasNoInputPlates } from 'common/types/bundleConfigUtils';
import { ErrorCodes } from 'common/types/errorCodes';
import { Position2d } from 'common/types/Position';
import { Schema } from 'common/types/schema';
import { DateRange } from 'common/ui/components/FilterChip/FilterChipWithDateRange';
import LinearProgress from 'common/ui/components/LinearProgress';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDebounce from 'common/ui/hooks/useDebounce';
import useDialog from 'common/ui/hooks/useDialog';
import useRefCallback from 'common/ui/hooks/useRefCallback';

const DEBOUNCED_AUTOSAVE_INTERVAL_MS = 1000;

type GraphQLElementSet = ElementSetQuery['elementSet'];

type Props = {
  workflowId: WorkflowId;
  doeTemplateMode?: boolean;
};

const WorkflowBuilder = React.memo(function WorkflowBuilder(props: Props) {
  const classes = useStyles();
  const workflowLayoutRef = useRef<HTMLDivElement | null>(null);
  const navigation = useNavigation();
  const snackbar = useSnackbarManager();
  const fetchGraphQLElementSet = useFetchGraphQLElementSet();
  const {
    config: workflowConfig,
    InstancesConnections,
    elementInstances,
    parameters,
    workflowName,
    editMode,
    template,
    elementSet,
    source,
    activePanel,
    elementGroups,
    stages,
    schema,
    factors,
    dragError,
    selectedStageId,
    lastNudged,
    visibleCanvasArea,
  } = useWorkflowBuilderSelector(state => state);
  const dispatch = useWorkflowBuilderDispatch();

  const { handleCheckConflictError, conflictDialog } = useEntityConflictErrorDialog();
  const [simulationWarningDialog, openSimulationWarningDialog] = useDialog(
    InvalidWorkflowConfigDialog,
  );

  const elementsCascade = useMemo(() => new ElementsCascadeTracker(), []);

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoadingWorkflow, setIsLoadingWorkflow] = useState(true);
  const [lastSavedWorkflow, setLastSavedWorkflow] =
    useState<SuccessfullySavedWorkflow | null>(null);
  const [hasWorkflowSaveFailed, setHasWorkflowSaveFailed] = useState(false);

  const isReadonly = isWorkflowReadonly(editMode, source);
  const [isSaving, setIsSaving] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [favorited, setFilterFavorited] = useState(false);
  // Right now, only allow user to be able to filter by successful simulations even though
  // simulations technically can be in other intermediate states.
  const [filterSuccessfulSimulations, setFilterSuccessful] = useState(false);
  const [filterDateRange, setFilterDateRange] = useState<DateRange>({});

  const workspaceControlsRef = useRef<HTMLDivElement | null>(null);

  const { getElementInstanceNamesWithParameterErrors } =
    useContext(ParameterStateContext);

  const { exampleWorkflowsDialog } = useLaunchExampleWorkflowsDialogInBuilder(
    isLoadingWorkflow,
    isReadonly,
    elementInstances.length > 0,
  );

  useEffect(() => {
    const elementInstancesWithErrors = getElementInstanceNamesWithParameterErrors();
    const elementIds = elementInstances
      .filter(({ name }) => elementInstancesWithErrors.includes(name))
      .map(({ Id }) => Id);
    if (elementIds.length > 0) {
      // We don't want to dispatch an empty array here whenever the useEffect runs as this will
      // get added to our undo stack and impact undo actions order. If it is needed to reset the
      // errored objects, then call a dispatch explicitly when needed.
      dispatch({ type: 'setErroredObjects', payload: elementIds });
    }
  }, [dispatch, elementInstances, getElementInstanceNamesWithParameterErrors]);

  // Delay half a second before applying or cancelling a nudge so the user has a chance
  // to continue nudging or to see the validation error if the nudged elements are in an
  // invalid position.
  useEffect(() => {
    if (lastNudged) {
      const timeout = setTimeout(() => {
        dispatch({ type: 'applyNudge' });
      }, 500);

      return () => clearTimeout(timeout);
    }

    return undefined;
  }, [dispatch, lastNudged]);

  const {
    result: simulationsQueryResult,
    simulateWorkflowWithTracking,
    simulationsPoller,
    pollingErrors,
    isSimulating,
  } = useSimulations({
    workflowId: props.workflowId,
    searchQuery,
    favorited,
    filterSuccessfulSimulations,
    filterDateRange,
  });
  // Keeps track of how many simulations have happened when simulations panel is closed or when the
  // user is no longer at the top of the simulations panel.
  const [unreadSimulations, setUnreadSimulations] = useState(0);

  const latestSimulationIdFromWorkflow = useMemo<SimulationId | undefined>(() => {
    // We only want the latest actual simulation (not a placeholder).
    const latestNonPlaceholderSimulation =
      simulationsQueryResult.data?.simulationsForWorkflow?.items?.find(
        sim => !sim.id.startsWith(SIMULATION_PLACEHOLDER_ID),
      );
    return latestNonPlaceholderSimulation?.id;
  }, [simulationsQueryResult.data?.simulationsForWorkflow?.items]);

  const clearSimulationsPanelFilters = useCallback(() => {
    setSearchQuery('');
    setFilterFavorited(false);
    setFilterSuccessful(false);
    setFilterDateRange({});
  }, []);

  const createElementInstance = useCreateElementInstance();

  // Add a new instance of the specified element to the current workflow.
  // We use `useRefCallback` here as `elementInstances` invalidates too often with `useCallback`
  // and this causes performance problems.
  const addElementInstance = useRefCallback(
    async (elementId: string, droppedAtPosition?: Position2d) => {
      const element = elementSet?.elements.find(element => element.id === elementId);
      if (!element) {
        return;
      }

      const { targetPosition } = elementsCascade.getPositionAndIndexForNewElement(
        workflowLayoutRef,
        droppedAtPosition,
        stages,
        selectedStageId,
      );

      const { instance, params } = await createElementInstance(
        element,
        elementInstances,
        targetPosition,
      );

      dispatch({
        type: 'addElementInstance',
        payload: {
          elementInstance: instance,
          parameters: params,
        },
      });

      // Sometimes the new element might be offscreen, e.g. if the user has selected
      // a stage is that is not currently visible. In this case, re-center the
      // screen on the new element so the user is always aware of where it is.
      if (elementsCascade.isOffscreen(targetPosition, visibleCanvasArea)) {
        // Delay slightly so we have time to measure the added element.
        setTimeout(() => {
          dispatch({
            type: 'centerToElement',
            payload: { elementId: instance.Id, selectElement: false },
          });
        }, 10);
      }
    },
  );
  // Enable drag and drop of elements into the Workspace by dragging and dropping from
  // the Elements List panel
  const onDragAndDropElement = useDragAndDrop(
    workflowLayoutRef.current,
    isReadonly,
    addElementInstance,
  );

  const resetToCleanState = useCallback(() => {
    setErrorMessage('');
    setIsLoadingWorkflow(true);
    setIsSaving(false);
    setLastSavedWorkflow(null);
    setHasWorkflowSaveFailed(false);
    setLastSimulateClickTime(Date.now());
  }, []);

  const setManualDeviceAsDefaultState = useManualDeviceDefault();

  useEffect(() => {
    dispatch({ type: 'setIsSaving', payload: isSaving });
  }, [dispatch, isSaving]);

  const resetStateWithWorkflow = useCallback(
    (workflow: GraphQLWorkflow, elementSet: GraphQLElementSet) => {
      // We are in the unfortunate situation of relying on both  the WorkflowBuilderStateContext and local state.
      // Therefore we need to make sure to update both.
      const { workflowState, errors } = deserialiseWorkflowResponse(workflow, elementSet);
      if (errors.length > 0) {
        snackbar.showError(errors.join(' '));
      }

      const bundle = removeUnsavedDataFromServerBundle(workflow.workflow);

      dispatch({ type: 'resetToWorkflow', payload: workflowState });
      setLastSavedWorkflow({
        workflowId: props.workflowId,
        editVersion: workflow.version,
        bundle,
      });
    },
    [dispatch, props.workflowId, snackbar],
  );

  const handleSwitchElementBranch = useCallback(
    (result?: ElementBranchSwitcherResults) => {
      if (!result?.updatedWorkflow || !result?.selectedElementSet) {
        return;
      }
      resetStateWithWorkflow(result.updatedWorkflow, result.selectedElementSet);
    },
    [resetStateWithWorkflow],
  );

  const migrateWorkflow = WorkflowsApi.useMigrateWorkflow();
  const validateWorkflowAndWarnUser = useValidateWorkflowAndWarnUser();
  // Reset all state when we're given a new workflow to work with
  useEffect(() => {
    resetToCleanState();

    (async () => {
      try {
        // workflow after migration returns the element set with elements
        const workflow = await migrateWorkflow(props.workflowId);

        // elements are fetched separately so that they can be cached for multiple workflows
        const elementSet = await fetchGraphQLElementSet(workflow.workflow.elementSetId);

        validateWorkflowAndWarnUser(workflow, snackbar);
        resetStateWithWorkflow(workflow, elementSet);
        // Workflows could have been created which contain devices which don't exist. These will
        // always fail to simulate and may hide the deck options for liquid handlers, calling fix
        // devices updates the workflow state to remove any id's which don't make sense.
        dispatch({ type: 'fixDevices' });
      } catch (err) {
        console.error(err);
        setErrorMessage(getErrorMessage(err));
      } finally {
        // Make sure we set this to false as the very last thing.
        // Setting this to false means hiding the loading indicator and showing
        // the current state from WorkflowBuilderStateContext in the element plumber.
        setIsLoadingWorkflow(false);
      }
    })();
  }, [dispatch, fetchGraphQLElementSet, migrateWorkflow, props.workflowId, resetStateWithWorkflow, resetToCleanState, snackbar, validateWorkflowAndWarnUser]);

  const isNewWorkflow = lastSavedWorkflow?.editVersion === 1;
  useEffect(() => {
    if (isNewWorkflow) {
      setManualDeviceAsDefaultState(workflowConfig);
    }
  }, [isNewWorkflow, setManualDeviceAsDefaultState, workflowConfig]);

  const [lastSimulateClickTime, setLastSimulateClickTime] = useState(Date.now());
  const latestFailedSimulation = useMemo<
    | ArrayElement<simulationsForWorkflowQuery['simulationsForWorkflow']['items']>
    | undefined
  >(() => {
    return simulationsQueryResult.data?.simulationsForWorkflow?.items?.find(
      sim => sim.status === 'FAILED',
    );
  }, [simulationsQueryResult.data?.simulationsForWorkflow?.items]);

  // Show highlighting if the recent completed simulation is a failure.
  useEffect(() => {
    if (
      !isReadonly &&
      latestFailedSimulation &&
      moment(latestFailedSimulation.startedAt).isAfter(lastSimulateClickTime)
    ) {
      dispatch({
        type: 'showErroredElementInstance',
        payload: latestFailedSimulation.errors[0]?.context,
      });
    }
  }, [dispatch, isReadonly, lastSimulateClickTime, latestFailedSimulation]);

  const checkWorkflowStatus = useCallback(async (): Promise<
    [simulate: false] | [simulate: true, forced: boolean]
  > => {
    const shouldCheckForNoInputPlates = workflowConfig.global.requiresDevice;
    const hasNoInputPlates = shouldCheckForNoInputPlates
      ? configHasNoInputPlates(workflowConfig)
      : false;

    let forcedSimulation = false;

    if (hasNoInputPlates) {
      const action = await openSimulationWarningDialog({
        problem: 'configHasNoInputPlates',
      });

      if (action === null) {
        return [false];
      } else if (action === 'resolve') {
        logEvent('config-opened-prior-to-simulating', ScreenRegistry.WORKFLOW);
        dispatch({ type: 'setActivePanel', payload: 'WorkflowSettings' });
        return [false];
      }

      forcedSimulation = true;
    }

    return [true, forcedSimulation];
  }, [dispatch, openSimulationWarningDialog, workflowConfig]);

  const requestSimulation = useCallback(
    async (workflow: SuccessfullySavedWorkflow) => {
      if (isSimulating) {
        return;
      }

      // Wait until after the workflow has saved to check if it has errors.
      const [simulate, forced] = await checkWorkflowStatus();

      if (!simulate) {
        return;
      }

      logEvent(forced ? 'simulate-anyway' : 'simulate', ScreenRegistry.WORKFLOW);
      window.Intercom('trackEvent', 'simulate');

      // When we simulate, we want to remove highlighted errored elements.
      dispatch({ type: 'setErroredObjects', payload: [] });
      setLastSimulateClickTime(Date.now());
      try {
        clearSimulationsPanelFilters();
        dispatch({ type: 'setActivePanel', payload: 'Simulations' });
        await simulateWorkflowWithTracking(
          workflow.workflowId,
          workflow.editVersion,
          workflow.bundle.Meta.Name,
          activePanel === 'DOEBuilder',
        );
        setUnreadSimulations(unreadSimulations + 1);
      } catch (err) {
        snackbar.showError(err.message);
      }
    },
    [
      activePanel,
      checkWorkflowStatus,
      clearSimulationsPanelFilters,
      dispatch,
      isSimulating,
      simulateWorkflowWithTracking,
      snackbar,
      unreadSimulations,
    ],
  );

  const updateWorkflow = WorkflowsApi.useUpdateWorkflow();

  const updateElementsWithContexts = useCallback(
    (
      elementContexts: ElementContextMap | null,
      elementContextError: CoreError | null,
    ) => {
      if (!isReadonly) {
        if (elementContexts) {
          dispatch({ type: 'updateElementsWithContexts', payload: elementContexts });
        } else {
          dispatch({ type: 'setElementContextError', payload: elementContextError });
        }
      }
    },
    [isReadonly, dispatch],
  );

  const debouncedSaveWorkflow = useDebounce(
    useCallback(
      async (
        lastWorkflow: SuccessfullySavedWorkflow,
        workflowName: string,
        workflowSchemaVersion: string,
        elementInstances: ElementInstance[],
        InstancesConnections: Connection[],
        groups: Group[],
        stages: Stage[],
        parameters: BundleParameters,
        elementSetId: string,
        config: WorkflowConfig,
        factors: Factors | null,
        template?: TemplateWorkflow,
        schema?: Schema,
      ) => {
        const currentBundle = buildWorkflowBuilderStateBundle({
          workflowMeta: {
            ...lastWorkflow.bundle.Meta,
            Name: workflowName,
          },
          workflowSchemaVersion,
          elementInstances,
          elementSetId,
          InstancesConnections,
          parameters,
          config,
          template,
          groups,
          stages,
          schema,
          factors,
        });

        // If the values haven't changed and user did not click "Simulate",
        //  there's no need to save an update.
        if (equivalentBundles(currentBundle, lastWorkflow.bundle)) {
          return;
        }

        // Don't save while the conflict dialog is open, as browser tabs in
        //  the background would otherwise keep firing requests.
        if (conflictDialog) {
          return;
        }
        try {
          setIsSaving(true);

          const {
            version: newEditVersion,
            elementContextMap,
            elementContextError,
          } = await updateWorkflow(
            lastWorkflow.workflowId,
            lastWorkflow.editVersion,
            currentBundle.Meta.Name,
            currentBundle,
          );
          // It's been saved now. Update the last saved state.
          const latestWorkflow = {
            workflowId: lastWorkflow.workflowId,
            editVersion: newEditVersion,
            bundle: currentBundle,
          };
          updateElementsWithContexts(elementContextMap, elementContextError);

          if (elementContextError) {
            snackbar.showError('An error occurred while validating the workflow.');
          }

          setLastSavedWorkflow(latestWorkflow);
          setHasWorkflowSaveFailed(false);
        } catch (e) {
          await handleCheckConflictError(
            e,
            'workflow',
            ErrorCodes.WORKFLOW_EDIT_CONFLICT,
          );
        } finally {
          setIsSaving(false);
        }
      },
      [
        conflictDialog,
        updateWorkflow,
        updateElementsWithContexts,
        snackbar,
        handleCheckConflictError,
      ],
    ),
    DEBOUNCED_AUTOSAVE_INTERVAL_MS,
  );

  const callSaveWorkflow = useCallback(
    async (saveImmediately?: boolean) => {
      if (isReadonly) {
        return;
      }
      if (!lastSavedWorkflow?.bundle || isSaving) {
        return;
      }
      if (!elementSet) {
        // Cannot save, we need to know the selected element set
        return;
      }

      debouncedSaveWorkflow(
        lastSavedWorkflow,
        workflowName,
        elementSet.workflowSchemaVersion,
        elementInstances,
        InstancesConnections,
        elementGroups,
        stages,
        parameters ?? {},
        elementSet.id,
        workflowConfig,
        factors,
        template,
        schema,
      );

      if (saveImmediately) {
        // If we want to simulate, we shouldn't wait. We should save immediately.
        debouncedSaveWorkflow.flush();
      }
    },
    [
      isReadonly,
      lastSavedWorkflow,
      isSaving,
      elementSet,
      debouncedSaveWorkflow,
      workflowName,
      elementInstances,
      InstancesConnections,
      elementGroups,
      stages,
      schema,
      parameters,
      workflowConfig,
      factors,
      template,
    ],
  );

  useEffect(() => {
    let isOutdated = false;

    if (!isOutdated) {
      void callSaveWorkflow();
    }

    return () => {
      isOutdated = true;
    };
  }, [lastSavedWorkflow, callSaveWorkflow]);

  const handleCopyWorkflow = WorkflowsApi.useCopyWorkflowAndNavigate(
    lastSavedWorkflow?.workflowId,
    lastSavedWorkflow?.editVersion,
    props.doeTemplateMode ? EditorType.DOE_TEMPLATE : EditorType.WORKFLOW_EDITOR,
  );

  const shouldSimulateAfterSaving = useRef(false);

  const startSimulation = useCallback(async () => {
    if (!lastSavedWorkflow) {
      console.error("Cannot simulate, don't have a valid saved workflow.");
      return;
    }

    if (isSaving) {
      shouldSimulateAfterSaving.current = true;
      return;
    }

    // This is the most up-to-date bundle, it reflects changes in the UI
    // in real time.
    const currentWorkflowBuilderStateBundle = buildWorkflowBuilderStateBundle({
      workflowMeta: {
        ...lastSavedWorkflow.bundle.Meta,
        Name: workflowName,
      },
      template: template,
      config: workflowConfig,
      elementSetId: elementSet!.id,
      InstancesConnections: InstancesConnections,
      groups: elementGroups,
      stages,
      parameters: parameters,
      elementInstances: elementInstances,
      workflowSchemaVersion: elementSet!.workflowSchemaVersion,
      factors,
    });

    // Check if user clicked simulate before we had a chance
    // to save their latest changes.
    const shouldSaveBeforeSimulating = !equivalentBundles(
      currentWorkflowBuilderStateBundle,
      lastSavedWorkflow.bundle,
    );

    if (shouldSaveBeforeSimulating) {
      shouldSimulateAfterSaving.current = true;
      await callSaveWorkflow(true);
    } else {
      await requestSimulation(lastSavedWorkflow);
    }
  }, [
    lastSavedWorkflow,
    isSaving,
    workflowName,
    template,
    workflowConfig,
    elementSet,
    InstancesConnections,
    elementGroups,
    stages,
    parameters,
    elementInstances,
    factors,
    callSaveWorkflow,
    requestSimulation,
  ]);

  // Check if we need to simulate after we are done saving.
  useEffect(() => {
    if (!shouldSimulateAfterSaving.current || !lastSavedWorkflow) {
      return;
    }

    if (isSaving) {
      return;
    }

    void startSimulation();
    shouldSimulateAfterSaving.current = false;
  }, [isSaving, lastSavedWorkflow, startSimulation]);

  const handleSimulate = useCallback(async () => {
    await startSimulation();
  }, [startSimulation]);

  const handleLaunchDOEDesignTool = useCallback(async () => {
    if (!lastSavedWorkflow) {
      console.error(
        `Could not launch DOE design tool: the current workflow has not finished loading.`,
      );
      return;
    }
    navigation.navigate(doeTemplateRoutes.design, {
      workflowId: lastSavedWorkflow.workflowId,
    });
  }, [lastSavedWorkflow, navigation]);

  const handleActivePanelChange = useCallback(
    (panel: PanelContent) => {
      dispatch({ type: 'setActivePanel', payload: panel });
    },
    [dispatch],
  );
  const elementsListProps = useMemo(() => {
    return {
      onElementSelect: addElementInstance,
      onSwitchElementSet: doNothing,
      onDragAndDropElement: onDragAndDropElement,
    };
  }, [addElementInstance, onDragAndDropElement]);

  const canCopy = !isReadonly;
  const canPaste = !isReadonly && !props.doeTemplateMode;
  useCopyPasteIntoBuilder(canCopy, canPaste);

  // Check error first. If there is an error make sure we definitely show it and stop.
  if (errorMessage) {
    return <UIErrorBox>{errorMessage}</UIErrorBox>;
  }

  if (isLoadingWorkflow) {
    return <LinearProgress />;
  }

  if (!elementSet) {
    return (
      <UIErrorBox>
        Sorry. We could not retrieve the element set. Please contact our Support team for
        assistance.
      </UIErrorBox>
    );
  }

  return (
    <>
      <RouteLeavingGuard when={isSaving} />
      <div className={classes.builderContainer}>
        <WorkflowTopBar
          workflowId={props.workflowId}
          onCopyWorkflow={handleCopyWorkflow}
          simulationId={latestSimulationIdFromWorkflow}
        />
        <div
          className={classes.workflowContainer}
          ref={workflowLayoutRef}
          onDragOver={doPreventDefault}
        >
          <ElementPlumber
            onPositionChange={elementsCascade.onPositionChange}
            doeTemplateMode={props.doeTemplateMode}
            areElementsLoading={isSaving}
            workspaceControlsPortal={workspaceControlsRef.current}
            status={
              <WorkflowBuilderStatus
                isReadonly={isReadonly}
                isSaving={isSaving}
                hasWorkflowSaveFailed={hasWorkflowSaveFailed}
                dragError={dragError}
              />
            }
          />
          <ControlOverlay
            elementsListProps={elementsListProps}
            DOETemplateMode={!!props.doeTemplateMode}
            onActivePanelChange={handleActivePanelChange}
            isReadonly={isReadonly}
            onSimulate={handleSimulate}
            onSwitchElementBranch={handleSwitchElementBranch}
            onLaunchDOEDesignTool={handleLaunchDOEDesignTool}
            workflowId={props.workflowId}
            workflowConfig={workflowConfig}
            simulationsForWorkflowQueryResult={simulationsQueryResult}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            favorited={favorited}
            setFilterFavorited={setFilterFavorited}
            filterSuccessfulSimulations={filterSuccessfulSimulations}
            setFilterSuccessful={setFilterSuccessful}
            filterDateRange={filterDateRange}
            setFilterDateRange={setFilterDateRange}
            unreadSimulations={unreadSimulations}
            setUnreadSimulations={setUnreadSimulations}
            pollingErrors={pollingErrors}
            isSimulationBusy={isSimulating || shouldSimulateAfterSaving.current}
            isSaving={isSaving}
            workspaceControlsRef={workspaceControlsRef}
          />
        </div>
        {stages.length > 0 && <TimeLine disabled={isReadonly} />}
      </div>
      {conflictDialog}
      {simulationsPoller}
      {simulationWarningDialog}
      {exampleWorkflowsDialog}
    </>
  );
});

const WorkflowBuilderWithContext = (props: Props) => {
  const { parameters, stagedParameters, elementInstances, InstancesConnections } =
    useWorkflowBuilderSelector(state => {
      const { parameters, stagedParameters, elementInstances, InstancesConnections } =
        state;
      return {
        parameters,
        stagedParameters,
        elementInstances,
        InstancesConnections,
      };
    });
  return (
    <ParameterStateContextProvider
      parameters={parameters}
      elementInstances={elementInstances}
      connections={InstancesConnections}
    >
      <AutocompleteParameterValuesContextProvider
        parameters={parameters}
        stagedParameters={stagedParameters}
        instances={elementInstances}
      >
        <WorkflowBuilder {...props} />
      </AutocompleteParameterValuesContextProvider>
    </ParameterStateContextProvider>
  );
};

const useStyles = makeStylesHook({
  builderContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
  },
  workflowContainer: {
    display: 'flex',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    position: 'relative',
  },
});

export default WorkflowBuilderWithContext;
