import React from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

import {
  ENTITY_CARD_COLUMN_TEMPLATE,
  RecentProtocolInstancesListHeader,
} from 'client/app/apps/protocols/RecentProtocolInstanceList/RecentProtocolInstancesListHeader';

const LoadingSkeleton = () => {
  return (
    <Box>
      <RecentProtocolInstancesListHeader />
      <Content>
        <Skeleton variant="text" sx={{ gridColumn: 'name' }} />
        <Skeleton variant="text" sx={{ gridColumn: 'author' }} />
        <Skeleton variant="text" sx={{ gridColumn: 'date' }} />
      </Content>
    </Box>
  );
};

const Content = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: ENTITY_CARD_COLUMN_TEMPLATE,
  gap: theme.spacing(5),
  height: '48px',
  overflow: 'hidden',
  alignItems: 'center',
}));

export { LoadingSkeleton };
