import { sanitize } from 'dompurify';
import produce from 'immer';
import entries from 'lodash/entries';
import groupBy from 'lodash/groupBy';

import { State } from 'client/app/apps/policy-library/components/PolicyDialogStateContext';
import {
  addColumn,
  getTableStateFromConfig,
  initDataTable,
  initTableConfig,
} from 'client/app/apps/policy-library/components/ruleTableConfig';
import {
  allConditions,
  allConsequences,
  getAllowedUnits,
  getRuleMeta,
  RuleMetaKey,
} from 'client/app/apps/policy-library/RulesMeta';
import formatRuleConsequenceValueRecursive from 'client/app/components/Parameters/Policy/components/formatRuleConsequenceValueRecursive';
import { ArrayElement, LiquidPolicy, LiquidPolicyInput } from 'client/app/gql';
import {
  capitalize,
  formatMeasurementObj,
  getOrderOperatorEncoding,
  getOrderOperatorExpression,
  parseMeasurement,
  validateMeasurementExpression,
} from 'common/lib/format';
import { LIQUID_TYPE_VAR, RULE_NAME_FIELD } from 'common/lib/liquidPolicies';
import { Measurement, Rule } from 'common/types/mix';
import { CellValue, Row } from 'common/types/spreadsheetEditor';

export const RULES_TABLE_UPDATE_DEBOUNCE_MS = 500;

export {
  buildPolicyInput,
  buildPolicyState,
  getConditionCellValue,
  parseConditionExpression,
};

/**
 * Transforms dialog state for Liquid Policy into a graphql input where `rule`
 * are compatible with the `customPolicyRuleSet` object format
 */
function buildPolicyInput(policy: State['policy']): LiquidPolicyInput {
  if (!policy.name.value) {
    throw new Error('Liquid policy name is not defined');
  }
  if (!policy.description.value) {
    throw new Error('Liquid policy description is not defined');
  }
  const policyName = sanitize(policy.name.value.trim());
  const policyDesc = sanitize(policy.description.value.trim());
  return {
    name: policyName,
    description: policyDesc,
    rules: buildRules(policy.rules.dataTable.data, policyName),
  };
}

/**
 * Transforms rules from DataTable into format compatible with `customPolicyRuleSet`
 */
function buildRules(rows: Row[], policyName: string): Rule[] {
  return rows.map(row => {
    const name = row[RULE_NAME_FIELD] as string;
    const conditions: Rule['conditions'] = [
      {
        variable: LIQUID_TYPE_VAR,
        values: [policyName],
      },
    ];
    const consequences: Rule['consequences'] = {};

    for (const key in row) {
      const cellValue = row[key];

      if (cellValue == null) continue;

      // Process conditions
      if (key in allConditions) {
        conditions.push(...buildConditionsForVariable(key, cellValue));

        // Process consequences
      } else if (key in allConsequences) {
        let measurement: Measurement | undefined;
        if (typeof cellValue === 'string') {
          measurement = parseMeasurement(cellValue);
        }
        // TODO: handle complex volume types VolumeConsequence
        consequences[key] = measurement ?? cellValue;
      }
    }
    return {
      name,
      source: 'user',
      conditions,
      consequences,
    };
  });
}

/**
 * Parses table cell value and creates corresponding conditions.
 *
 * There might be multiple conditions related to the same condition variable:
 *
 * e.g. `destination_maxvolume: >= 50ul & <= 100ul`
 *
 * equals to
 *
 * ```
 * [
 *  { variable: "destination_maxvolume", value: { value: 50, unit: "ul" }, operator: "gte" },
 *  { variable: "destination_maxvolume", value: { value: 100, unit: "ul" }, operator: "lte" }
 * ]
 * ```
 */
function buildConditionsForVariable(
  variable: string,
  cellValue: string | number | boolean,
): NonNullable<Rule['conditions']> {
  if (typeof cellValue === 'number' || typeof cellValue === 'boolean') {
    return [
      {
        variable,
        values: [String(cellValue)],
      },
    ];
  }

  const allowedUnits = getAllowedUnits(variable);
  if (checkIsLogicalExpression(cellValue)) {
    // Here `cellValue` is a logical expression.
    // So we parse the expression into multiple resulting conditions.
    return parseConditionExpression(cellValue, variable);
  } else if (allowedUnits) {
    // If a condition expects to have allowed units then the cell value must be a logical expression.
    throw new MeasurementConditionError(variable, allowedUnits);
  } else {
    // Here `cellValue` is `string` that has a semicolon separator.
    // This is necessary to handle cases when there are multiple values in a single cell.
    // e.g. Cell with tip types: "Fluent Tips, 20ul"; "Fluent Tips, 100ul"
    return [
      {
        variable,
        values: cellValue.split(';').map(s => s.trim()),
      },
    ];
  }
}

/**
 * Parses `cellValue` string into multiple Rule conditions.
 */
function parseConditionExpression(cellValue: string, variable: string) {
  const allowedUnits = getAllowedUnits(variable);
  if (!allowedUnits) {
    throw new Error(`No allowed units found for condition: ${variable}`);
  }

  const isValidCondition = validateMeasurementExpression(cellValue, allowedUnits);
  if (!isValidCondition) {
    throw new MeasurementConditionError(variable, allowedUnits);
  }

  const resultConditions: Rule['conditions'] = [];

  for (const rawConditionStr of cellValue.split('&')) {
    let operator: string | undefined;
    let measurement: string | undefined;

    for (const logicalOperator of ['>=', '<=', '>', '<', '=']) {
      if (rawConditionStr.includes(logicalOperator)) {
        operator = logicalOperator;
        measurement = rawConditionStr.split(logicalOperator).at(1);
        break;
      }
    }

    if (!measurement || !operator || checkIsLogicalExpression(measurement)) {
      throw new MeasurementConditionError(variable, allowedUnits);
    }

    resultConditions.push({
      variable,
      value: parseMeasurement(measurement),
      operator: operator ? getOrderOperatorEncoding(operator) : undefined,
    });
  }

  return resultConditions;
}

/**
 * Checks if cell text content is a logical expression
 */
function checkIsLogicalExpression(cellValue: string) {
  let isLogicalExpression = false;
  for (const logicalOperator of ['>=', '<=', '>', '<', '=']) {
    if (cellValue.includes(logicalOperator)) {
      isLogicalExpression = true;
      break;
    }
  }
  return isLogicalExpression;
}

/**
 * Transforms LiquidPolicy object into the EditorDialog input
 */
function buildPolicyState(policy: LiquidPolicy): State['policy'] {
  const sortedRules = [...policy.rules].sort((a, b) => a.ruleOrder - b.ruleOrder);
  return {
    name: {
      value: policy.name,
      error: undefined,
    },
    description: {
      value: policy.description,
      error: undefined,
    },
    rules: buildRulesTable(sortedRules.map(r => r.ruleData)),
  };
}

/**
 * Transforms liquid policy rules into a `DataTable` with `TableConfiguration` and state
 * @param rules sorted rules of the given liquid policy
 */
function buildRulesTable(rules: Rule[]): State['policy']['rules'] {
  const { dataTable, tableConfig } = produce(
    { dataTable: initDataTable, tableConfig: initTableConfig },
    draft => {
      draft.dataTable.data = rules.map(rule => {
        const row: Record<string, CellValue> = { [RULE_NAME_FIELD]: rule.name };

        // Start populating with conditions
        entries(
          groupBy(
            rule.conditions?.filter(condition => condition.variable !== LIQUID_TYPE_VAR),
            'variable',
          ),
        ).forEach(([variable, conditions]) => {
          const existConditionColumn = draft.dataTable.schema.fields.some(
            field => field.name === variable,
          );

          if (!existConditionColumn) {
            addColumn({
              table: draft.dataTable,
              config: draft.tableConfig,
              column: {
                type: 'condition',
                name: variable as RuleMetaKey,
              },
            });
          }
          row[variable] = getConditionCellValue(conditions);
        });

        // Populate consequences
        entries(rule.consequences).forEach(([name, value]) => {
          const existConsequenceColumn = draft.dataTable.schema.fields.some(
            field => field.name === name,
          );

          if (!existConsequenceColumn) {
            addColumn({
              table: draft.dataTable,
              config: draft.tableConfig,
              column: {
                type: 'consequence',
                name: name as RuleMetaKey,
              },
            });
          }

          let cellValue: CellValue;
          if (typeof value === 'object') {
            cellValue = formatRuleConsequenceValueRecursive(value);
          } else {
            cellValue = value;
          }
          row[name] = cellValue;
        });

        return row;
      });
    },
  );

  return {
    dataTable,
    tableConfig,
    tableState: getTableStateFromConfig(tableConfig),
  };
}

/**
 * Combines conditions into a single string value for the table cell.
 *
 * There might be multiple conditions related to the same condition variable:
 *
 * e.g. `destination_maxvolume: >= 50ul & <= 100ul`
 *
 * equals to
 *
 * ```
 * [
 *  { variable: "destination_maxvolume", value: { value: 50, unit: "ul" }, operator: "gte" },
 *  { variable: "destination_maxvolume", value: { value: 100, unit: "ul" }, operator: "lte" }
 * ]
 * ```
 */
function getConditionCellValue(conditions: NonNullable<Rule['conditions']>): CellValue {
  if (conditions.length > 1) {
    return conditions.map(mapSingleConditionValue).join(' & ');
  }
  return mapSingleConditionValue(conditions[0]);
}

function mapSingleConditionValue(
  condition: ArrayElement<NonNullable<Rule['conditions']>>,
) {
  if (condition.value && condition.operator) {
    return `${getOrderOperatorExpression(condition.operator)} ${formatMeasurementObj(
      condition.value,
    )}`;
  }
  if (condition.values) {
    return condition.values.join(' ; ');
  }
  return null;
}

/**
 * @class Custom error for measurement conditions.
 * Must include description of a condition and it's allowed units.
 */
export class MeasurementConditionError extends Error {
  constructor(conditionVariable: string, allowedUnits: string[]) {
    super();
    this.message = this.buildMessage(conditionVariable, allowedUnits);
  }

  private buildMessage(conditionVariable: string, allowedUnits: string[]) {
    const ruleMeta = getRuleMeta('condition', conditionVariable as RuleMetaKey);
    const conditionLabel = capitalize(ruleMeta.label);
    return `Invalid format of condition "${conditionLabel}". Please make sure to check the help information available in the column header. It can only include SI prefixed units e.g. ${allowedUnits
      .slice(0, 3)
      .map(unit => `"${unit}"`)
      .join(', ')}.`;
  }
}
