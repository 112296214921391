import React from 'react';

import Button from '@mui/material/Button';

import { OutputStepErrors } from 'client/app/apps/protocols/OutputStepErrors';
import {
  StepResponse,
  StepResponseById,
} from 'client/app/apps/protocols/OutputStepResponse/useStepsReponse';
import { pluralizeWord } from 'common/lib/format';

type Props = {
  selectedResponse: StepResponse;
  otherResponses: StepResponseById;
  handleSelectStep: (stepId: string) => void;
  onClearSimulationError: () => void;
};

export function ErrorsResponse({
  selectedResponse,
  otherResponses,
  handleSelectStep,
  onClearSimulationError,
}: Props) {
  const {
    noStep: noStepErrors,
    simulation: simErrors,
    ...otherStepResponses
  } = otherResponses;

  return (
    <>
      <OutputStepErrors errors={selectedResponse.errors} severity="error" />
      <OutputStepErrors errors={selectedResponse.warnings} severity="warning" />
      {Object.entries(otherStepResponses).map(([stepId, { errors, warnings }]) => (
        <>
          <OutputStepErrors
            infoChildren={
              <GoToErrorStep
                items={errors}
                iType="error"
                onClick={() => handleSelectStep(stepId)}
              />
            }
            errors={errors}
            severity="error"
          />
          <OutputStepErrors
            infoChildren={
              <GoToErrorStep
                items={warnings}
                iType="warning"
                onClick={() => handleSelectStep(stepId)}
              />
            }
            errors={warnings}
            severity="warning"
          />
        </>
      ))}
      <OutputStepErrors
        infoChildren={
          <>
            There {isOrAre(noStepErrors.errors, 'error')} that prevent simulation of this
            protocol.
          </>
        }
        errors={noStepErrors.errors}
        severity="error"
      />
      <OutputStepErrors
        // infoChildren - Not really sure where to point the user here...
        errors={noStepErrors.warnings}
        severity="warning"
      />
      <OutputStepErrors
        // in reality sim errors are always singular atm
        infoChildren={
          <>
            Simulation of the protocol resulted in an error.
            <Button color="inherit" onClick={onClearSimulationError}>
              DISMISS
            </Button>
          </>
        }
        errors={simErrors.errors}
        severity="error"
      />
    </>
  );
}

function GoToErrorStep<T>(props: { items: T[]; iType: string; onClick: () => void }) {
  const { items, iType, onClick } = props;
  return (
    <>
      There {isOrAre(items, iType)} in another step.
      <Button color="inherit" onClick={onClick}>
        GO TO STEP
      </Button>
    </>
  );
}

function isOrAre<T>(items: T[], word: string) {
  const n = items.length;
  return `${pluralizeWord(n, 'is a', 'are')} ${pluralizeWord(n, word)}`;
}
