import React, { useCallback, useEffect, useState } from 'react';

import UIErrorBox from 'client/app/components/UIErrorBox';
import { protocolInstanceRoutes } from 'client/app/lib/nav/actions';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';

type Props = {
  id: ProtocolId;
  version: number;
};

// TODO: This is based on /antha-platform/antha-com/client/app/apps/template-workflows/TemplateWorkflowCreateFromSimulation.tsx
// replace with ProtocolAPI call when implemented
const createProtocolInstance = async (id: string, version: number) => {
  return {
    id,
    version,
  };
};

export default function CreateProtocolInstance({ id, version }: Props) {
  const [error, setError] = useState<Error | null>(null);
  const { navigate } = useNavigation();

  const createProtocolInstanceAndShowError = useCallback(
    async (protocolId: string, version: number) => {
      const { id } = await createProtocolInstance(protocolId, version);
      navigate(
        protocolInstanceRoutes.editProtocolInstance,
        { id },
        { method: 'replace' },
      );
    },
    [navigate],
  );

  useEffect(() => {
    createProtocolInstanceAndShowError(id, version).catch(setError);
  }, [createProtocolInstanceAndShowError, id, version]);

  return (
    <>
      {error && (
        <UIErrorBox>Could not create the protocol use: {error.message}</UIErrorBox>
      )}
    </>
  );
}
