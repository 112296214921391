import React from 'react';

import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

export default function ExecuteButton(props: {
  tooltip: string;
  onClick: () => void;
  disabled: boolean;
}) {
  const { tooltip, onClick, disabled } = props;
  return (
    <Tooltip title={tooltip}>
      <span>
        <StyledFab
          data-heap-tracking="start-execution-button"
          color="secondary"
          size="medium"
          variant="extended"
          onClick={onClick}
          disabled={disabled}
        >
          Start Execution
        </StyledFab>
      </span>
    </Tooltip>
  );
}

const StyledFab = styled(Fab)(({ theme: { spacing } }) => ({
  gap: spacing(3),
}));
