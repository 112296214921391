import React from 'react';

import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';

import { QUERY_PROTOCOLS_BY_WORKFLOW } from 'client/app/api/gql/queries';
import { TopBar } from 'client/app/components/TopBar';
import { protocolsRoutes } from 'client/app/lib/nav/actions';
import { ScreenRegistry } from 'client/app/registry';
import Button from 'common/ui/components/Button';
import RouteButton from 'common/ui/components/navigation/RouteButton';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { ProtocolIcon } from 'common/ui/icons';

type Props = {
  workflowId: WorkflowId;
};

export function ProtocolInBuilderWorkflowBar({ workflowId }: Props) {
  const snackbarManager = useSnackbarManager();
  const { data, loading } = useQuery(QUERY_PROTOCOLS_BY_WORKFLOW, {
    variables: { workflowId },
    onError: err => snackbarManager.showError(`Error getting protocol: ${err.message}`),
  });

  return (
    <TopBar>
      <Typography variant="h6" color="textPrimary">
        This workflow powers a Protocol. If you delete an element, its inputs and outputs
        will be removed from the protocol as well
      </Typography>

      {loading || (data?.protocols?.items?.length ?? 0) < 1 ? (
        <Button
          data-heap-tracking="readonlybar-copy-workflow-button"
          onClick={() => {}}
          disabled
          startIcon={<ProtocolIcon />}
          variant="primary"
          color="primary"
        >
          Back to Protocol
        </Button>
      ) : (
        <RouteButton
          data-heap-tracking="readonlybar-go-to-protocol-button"
          route={protocolsRoutes.editProtocol}
          routeParam={{
            id: data!.protocols.items[0].id,
            version: data!.protocols.items[0].version,
          }}
          label="Back to Protocol"
          startIcon={<ProtocolIcon />}
          logEventCategory={ScreenRegistry.WORKFLOW}
          color="primary"
          variant="contained"
        />
      )}
    </TopBar>
  );
}
