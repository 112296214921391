import React, { useState } from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { Name } from 'client/app/apps/protocols/EditableName';
import DeleteButton from 'client/app/apps/protocols/StepCard/DeleteButton';
import { ProtocolStepItem } from 'common/types/Protocol';

type Props<T extends ProtocolStepItem> = {
  items: T[];
  emptyMessage: string;
  onDelete?: (index: number) => void;
};

export default function StepContents<T extends ProtocolStepItem>({
  items,
  emptyMessage,
  onDelete,
}: Props<T>) {
  const [activeItemIndex, setActiveItemIndex] = useState<number | undefined>();

  if (items.length === 0) {
    return (
      <Typography variant="body1" component="i">
        {emptyMessage}
      </Typography>
    );
  }

  return (
    <StyledContentsWrapper variant="outlined">
      <List>
        {items.map((item, index) => {
          return (
            <ListItem
              key={item.id}
              onMouseEnter={() => setActiveItemIndex(index)}
              onMouseLeave={() => setActiveItemIndex(undefined)}
              secondaryAction={
                activeItemIndex === index &&
                onDelete && <DeleteButton onClick={() => onDelete(index)} />
              }
            >
              <Name>{item.displayName}</Name>
            </ListItem>
          );
        })}
      </List>
    </StyledContentsWrapper>
  );
}

const StyledContentsWrapper = styled(Paper)({
  borderRadius: '4px',
});
