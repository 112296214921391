import React, { useCallback, useEffect, useRef } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  useMutation,
  useQuery,
} from '@apollo/client';
import LinearProgress from '@mui/material/LinearProgress';

import {
  MUTATION_MIGRATE_WORKFLOW,
  MUTATION_UPDATE_WORKFLOW,
} from 'client/app/api/gql/mutations';
import { QUERY_WORKFLOW_BY_ID } from 'client/app/api/gql/queries';
import { useCopyWorkflow } from 'client/app/api/WorkflowsApi';
import TemplateWorkflowForm from 'client/app/apps/template-workflows/TemplateWorkflowForm';
import { ReadonlyBar } from 'client/app/components/ReadonlyBar';
import CollapsibleNotificationManager from 'client/app/components/SimulationNotification/CollapsibleNotificationManager';
import UIErrorBox from 'client/app/components/UIErrorBox';
import {
  ContentType,
  MigrateWorkflowMutation,
  UpdateWorkflowMutation,
  UpdateWorkflowMutationVariables,
  WorkflowSourceEnum,
} from 'client/app/gql';
import { useElements } from 'client/app/hooks/useElements';
import { workflowRoutes } from 'client/app/lib/nav/actions';
import { useSimulationClient } from 'client/app/lib/SimulationClient';
import { ScreenRegistry } from 'client/app/registry';
import { useSimulationNotificationsDispatch } from 'client/app/state/SimulationNotificationsContext';
import { Element } from 'common/types/bundle';
import { ensureV3Config } from 'common/types/bundleTransforms';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const DEFAULT_ERROR_MSG =
  'Something went wrong. If the problem persists, send us a message using the Intercom!';

type Props = {
  id: WorkflowId;
};

export default React.memo((props: Props) => {
  const { id } = props;

  const componentMounted = useRef(true);
  useEffect(() => {
    componentMounted.current = true;
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const { data: workflowData, updateQuery } = useQuery(QUERY_WORKFLOW_BY_ID, {
    variables: {
      id,
    },
    // The migrateWorkflow mutation will fill the cache, no need to do 2 requests
    fetchPolicy: 'cache-only',
  });

  const [migrateWorkflow, { loading, error }] = useMutation(MUTATION_MIGRATE_WORKFLOW, {
    variables: { id },
    // If the component is unmounted before the mutation is completed, updateQuery will throw.
    // To guard against this, we use a `componentMounted` flag that should become undefined when unmounted.
    onCompleted: data =>
      componentMounted.current &&
      // TODO SYN-1022: This is a bit dodgy. It works because query and mutation operations
      // use the same fragment. What if that's not the case anymore? Perhaps the compiler
      // will complain. Perhaps not. Refetching seems to be a safer way to handle this.
      // It does mean a supplemental API request, not sure it's a problem here though.
      updateQuery(() => ({
        __typename: 'Query',
        workflow: data.migrateWorkflow.workflow,
      })),

    // refetchQueries: [QUERY_WORKFLOW_BY_ID],
  });

  const [saveWorkflow, { loading: saving }] = useMutation(MUTATION_UPDATE_WORKFLOW);

  useEffect(() => {
    // Fire and forget is OK here. This will cause a re-render once finished.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    migrateWorkflow();
  }, [migrateWorkflow]);

  let workflow = workflowData?.workflow;
  if (workflow) {
    // it's possible the workflow hasn't been migrated to v3 (e.g. if it's owned)
    // by someone else then 'migrateWorkflow' just fetches the workflow,
    // so we have to ensure the workflow is readable
    workflow = { ...workflow, workflow: ensureV3Config(workflow.workflow) };
  }
  const elementSetId = workflow?.workflow?.elementSetId;
  const { elements } = useElements(elementSetId);
  const areElementsLoading = elements === undefined;

  const navigation = useNavigation();
  const copyWorkflow = useCopyWorkflow();
  const handleCopyWorkflow = useCallback(async () => {
    if (!workflow) {
      console.error(
        'Could not copy workflow: the current workflow has not finished loading.',
      );
      return;
    }

    const { id: workflowId, version: editVersion } = workflow;
    const newWorkflowId = await copyWorkflow(workflowId, editVersion);

    navigation.navigate(workflowRoutes.editForm, {
      workflowId: newWorkflowId,
    });
  }, [copyWorkflow, navigation, workflow]);

  const classes = useStyles();

  if (error) return <GraphQLErrorPanel error={error} />;

  return (
    <>
      {loading || areElementsLoading || saving ? (
        <LinearProgress className={classes.linearProgress} />
      ) : null}
      {!areElementsLoading && workflow && (
        <ReadonlyBar
          authorName={workflow.createdBy.displayName}
          editMode={workflow.editMode}
          onCopyWorkflow={handleCopyWorkflow}
          currentEditorType={WorkflowSourceEnum.FORM_EDITOR}
          workflowEditorType={workflow.source}
          workflowContentSource={ContentType.USER_GENERATED}
          parentWorkflowID={workflow.parentWorkflowID}
        />
      )}
      <div className={classes.container}>
        {workflow && elements ? (
          <Content workflow={workflow} elements={elements} saveWorkflow={saveWorkflow} />
        ) : !loading && !error && !workflow ? (
          <UIErrorBox>Could not find Template with ID {props.id}</UIErrorBox>
        ) : null}
      </div>
      {elementSetId && workflow && (
        <CollapsibleNotificationManager
          workflowId={workflow.id}
          elementSetID={elementSetId}
        />
      )}
    </>
  );
});

type Workflow = MigrateWorkflowMutation['migrateWorkflow']['workflow'];

function Content({
  workflow,
  elements,
  saveWorkflow,
}: {
  workflow: Workflow;
  elements: readonly Element[];
  saveWorkflow: (
    options?: MutationFunctionOptions<
      UpdateWorkflowMutation,
      UpdateWorkflowMutationVariables
    >,
  ) => Promise<FetchResult<UpdateWorkflowMutation>>;
}) {
  const simulationService = useSimulationClient();
  const snackbarManager = useSnackbarManager();

  const dispatch = useSimulationNotificationsDispatch();

  const handleSimulate = useCallback(async () => {
    logEvent('simulate', ScreenRegistry.TEMPLATE_WORKFLOWS);
    window.Intercom('trackEvent', 'simulate-template-workflow');
    try {
      const { requestId: requestID } = simulationService.simulateWorkflow(
        workflow.id,
        workflow.version,
        result => {
          dispatch({
            type: 'updateSimulationNotification',
            payload: {
              info: result,
              status: 'SUCCESS',
            },
          });
        },
        progress => {
          dispatch({
            type: 'updateSimulationNotification',
            payload: {
              info: progress,
              status: 'IN_PROGRESS',
            },
          });
        },
        error => {
          dispatch({
            type: 'updateSimulationNotification',
            payload: {
              info: error,
              status: 'ERROR',
            },
          });
        },
      );
      // Add the notification because we've just kicked off the simulation
      dispatch({
        type: 'addSimulationNotification',
        payload: {
          requestID: requestID.toString(),
          status: 'IN_PROGRESS',
          source: WorkflowSourceEnum.FORM_EDITOR,
          name: workflow.name,
          startTime: new Date().toLocaleTimeString(),
          parentWorkflowId: workflow.id,
        },
      });
    } catch (e) {
      console.error(e.message || e);
      snackbarManager.showError(DEFAULT_ERROR_MSG);
    }
  }, [
    dispatch,
    simulationService,
    snackbarManager,
    workflow.id,
    workflow.name,
    workflow.version,
  ]);

  return (
    // set key to enforce re-render when id changes
    <TemplateWorkflowForm
      key={workflow.id}
      workflow={workflow}
      elements={elements}
      onStartSimulation={handleSimulate}
      saveWorkflow={saveWorkflow}
    />
  );
}

const useStyles = makeStylesHook({
  container: {
    overflow: 'auto',
    flex: '1 0',
    padding: '0 25%',
  },
  topSpace: {
    marginTop: '2em',
  },
  notificationsContainer: {
    position: 'sticky',
    bottom: 0,
    '& > div': {
      zIndex: 2,
    },
  },
  linearProgress: {
    alignSelf: 'stretch',
    flexShrink: 0,
  },
});
