import React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {
  OutputPreview,
  OutputPreviewSkeleton,
} from 'client/app/apps/workflow-builder/output-preview/OutputPreview';
import LabBenchBanner from 'common/assets/LabBenchBanner';
import Colors from 'common/ui/Colors';

export function OutputStepPreview() {
  return (
    <OutputPreviewWrapper>
      <OutputPreview />
    </OutputPreviewWrapper>
  );
}

export function NoOutputStepPreview({ callToAction }: { callToAction?: JSX.Element }) {
  return (
    <NoOutputPreviewWrapper>
      <LabBenchBanner sx={{ width: '684px', height: '382px' }} />
      <Typography variant="h3">This step has no outputs to preview.</Typography>
      {callToAction ?? null}
    </NoOutputPreviewWrapper>
  );
}

export function OutputStepPreviewSkeleton() {
  return (
    <OutputPreviewWrapper>
      <OutputPreviewSkeleton />
    </OutputPreviewWrapper>
  );
}

export const OutputStepPreviewPanel = styled('div', {
  shouldForwardProp: prop => prop !== 'noOutput',
})<{ noOutput: boolean }>(({ theme, noOutput }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(5),
  borderRadius: theme.spacing(0, 3, 3, 0),
  border: `1px solid ${Colors.GREY_30}`,
  borderLeft: 'none',
  backgroundColor: noOutput ? Colors.GREY_10 : 'white',
  justifyContent: 'spacing-between',
  width: '100%',
  overflow: 'clip',
}));

export const OutputStepPreviewTitle = styled(Typography)(() => ({
  fontWeight: 600,
}));

const NoOutputPreviewWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '95%',
  gap: theme.spacing(8),
  overflowX: 'clip',
}));

const OutputPreviewWrapper = styled('div')({
  height: '95%',
});
