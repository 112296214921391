import React from 'react';

import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { EmptyProtocolInstanceList } from 'client/app/apps/protocols/RecentProtocolInstanceList/EmptyProtocolInstanceList';
import { LoadingSkeleton } from 'client/app/apps/protocols/RecentProtocolInstanceList/LoadingSkeleton';
import { ProtocolInstancesList } from 'client/app/apps/protocols/RecentProtocolInstanceList/ProtocolInstancesList';
import { QUERY_PROTOCOL_INSTANCES } from 'client/app/apps/protocols/RecentProtocolInstanceList/queryProtocolInstances';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';

type Props = {
  protocolId: ProtocolId;
};

const RecentProtocolInstancesFetcher = ({ protocolId }: Props) => {
  const variables = {
    protocolId,
  };

  const protocolsQuery = useQuery(QUERY_PROTOCOL_INSTANCES, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  return (
    <InstanceList>
      <RenderQuery
        query={protocolsQuery}
        renderData={ProtocolInstancesList}
        additionalDataProps={{ protocolId }}
        renderNoData={EmptyProtocolInstanceList}
        loadingComponent={LoadingSkeleton}
        emptyCondition={data => data.protocolInstances.items.length === 0}
      />
    </InstanceList>
  );
};

const InstanceList = styled(Box)(() => ({
  overflow: 'hidden',
}));

export { RecentProtocolInstancesFetcher };
