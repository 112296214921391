import React from 'react';

import ExpandMore from '@mui/icons-material/ArrowDropDown';
import ExpandLess from '@mui/icons-material/ArrowDropUp';
import { styled } from '@mui/material/styles';

import Colors from 'common/ui/Colors';
import IconButton, { IconButtonProps } from 'common/ui/components/IconButton';
import Tooltip from 'common/ui/components/Tooltip';

type Props = {
  expanded: boolean;
  onClick: () => void;
  disabled?: boolean;
};

const DISABLED_TITLE = '';

function CardExpander({ expanded, onClick, disabled }: Props) {
  const title = expanded ? 'See less' : 'See more';
  const Icon = expanded ? <ExpandLess /> : <ExpandMore />;

  return (
    <Tooltip title={disabled ? DISABLED_TITLE : title}>
      <StyledButton
        icon={Icon}
        onClick={onClick}
        size="small"
        expanded={expanded}
        disabled={disabled}
      />
    </Tooltip>
  );
}

type StyledButtonProps = {
  expanded?: boolean;
} & IconButtonProps;

const StyledButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'expended',
})<StyledButtonProps>(({ expanded }) => ({
  gridColumn: 'expand',
  borderRadius: '6px 0 0 0',
  borderRight: `1px solid ${Colors.GREY_30}`,
  '&&': {
    height: 'auto',
    width: 'auto',
    alignSelf: 'stretch',
  },
  ...(expanded && {
    backgroundColor: Colors.INDIGO_90,
    borderRightColor: Colors.INDIGO_90,
    color: 'white',
    '&:hover': {
      backgroundColor: Colors.INDIGO_100,
    },
  }),
}));

export { CardExpander };
