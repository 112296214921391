import React from 'react';

import ExecuteButton from 'client/app/apps/simulation-details/ExecuteButton/ExecuteButton';
import useExecuteSimulation from 'client/app/apps/simulation-details/ExecuteButton/useExecuteSimulation';

type Props = {
  simulationId: SimulationId;
  hasStartedExecution: boolean;
};

export default function ExecuteSimulationButton({
  simulationId,
  hasStartedExecution,
}: Props) {
  const [createExecution, { loading }] = useExecuteSimulation(simulationId);
  const toolTip = hasStartedExecution
    ? 'This execution has already started. If you want to run it in the lab again, please re-simulate the workflow.'
    : '';

  return (
    <ExecuteButton
      tooltip={toolTip}
      disabled={hasStartedExecution || loading}
      onClick={createExecution}
    />
  );
}
