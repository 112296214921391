import { graphql } from 'client/app/gql';

export const QUERY_PROTOCOL_INSTANCES = graphql(/* GraphQL */ `
  query ProtocolInstances($protocolId: String, $search: String) {
    protocolInstances(protocolId: $protocolId, search: $search) {
      items {
        id
        name
        createdBy {
          id
          displayName
        }
        createdAt
      }
    }
  }
`);
