import React from 'react';

import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import Typography from '@mui/material/Typography';

import { TopBar } from 'client/app/components/TopBar';
import getWorkflowPropsBySource from 'client/app/lib/workflow/getWorkflowPropsBySource';
import { ScreenRegistry } from 'client/app/registry';
import { EditorType } from 'common/types/bundle';
import Button from 'common/ui/components/Button';
import RouteButton from 'common/ui/components/navigation/RouteButton';
import { TemplateWorkflowIcon } from 'common/ui/icons/TemplateWorkflow';

type Props = {
  workflowId: string;
  handleCopyWorkflow: () => void;
};

export function FormInBuilderWorkflowBar({ workflowId, handleCopyWorkflow }: Props) {
  const { route } = getWorkflowPropsBySource(EditorType.FORM_EDITOR);

  return (
    <TopBar>
      <Typography variant="h6" color="textPrimary">
        This is the workflow that powers the Form.
      </Typography>

      <Button
        data-heap-tracking="readonlybar-copy-form-button"
        onClick={handleCopyWorkflow}
        startIcon={<CopyIcon />}
        variant="primary"
        color="primary"
      >
        Create a copy in Builder
      </Button>

      <RouteButton
        data-heap-tracking="readonlybar-go-to-form-button"
        route={route}
        routeParam={{ workflowId }}
        label="Back to Form"
        startIcon={<TemplateWorkflowIcon />}
        logEventCategory={ScreenRegistry.WORKFLOW}
        color="primary"
        variant="contained"
      />
    </TopBar>
  );
}
