import React, { useEffect, useState } from 'react';

import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

import useElementErrorParser from 'client/app/components/ElementPlumber/useElementErrorParser';
import { ElementError } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import { MessagePreview, sxSmallerHeadings } from 'common/ui/components/MessagePreview';
import { DraggableProps } from 'common/ui/components/useDraggable';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const NO_ERRORS: ElementError[] = [];

type Props = {
  errors: ElementError[] | undefined;
} & Omit<TooltipProps, 'title'> &
  DraggableProps;

export default function ElementInstancePopover({
  errors = NO_ERRORS,
  boundaryRef,
  children,
  ...tooltipProps
}: Props & { children: React.ReactElement }) {
  const classes = useStyles();

  const parseError = useElementErrorParser();

  useTooltipRepositioning(tooltipProps.open, boundaryRef);

  const hasDetailsSection = errors.reduce(
    (flag, nextError) => (flag = flag || !!nextError.details),
    false,
  );

  return (
    <Popover
      {...tooltipProps}
      title={
        <div className={classes.container}>
          <section>
            <Typography variant="overline" className={classes.sectionHeader}>
              Summary
            </Typography>
            {errors.map(error => (
              <div key={error.code} className={classes.noGutters}>
                <MessagePreview
                  message={parseError(error.message)}
                  messageType={error.messageType}
                  sx={sxSmallerHeadings}
                />
              </div>
            ))}
          </section>
          {hasDetailsSection && (
            <section>
              <Typography variant="overline" className={classes.sectionHeader}>
                Details
              </Typography>
              {errors.map((error, idx) => (
                <MessagePreview
                  key={idx}
                  message={parseError(error.details) ?? ''}
                  messageType={error.messageType}
                  sx={sxSmallerHeadings}
                />
              ))}
            </section>
          )}
        </div>
      }
    >
      {children}
    </Popover>
  );
}

/**
 * This effect is necessary to stick the tooltip with the element instance while dragging the Workspace.
 */
function useTooltipRepositioning(
  isOpen: boolean | undefined,
  boundaryRef: React.RefObject<HTMLElement> | undefined,
) {
  const [_, rerender] = useState({});
  useEffect(() => {
    if (!isOpen) return;

    const boundaryElement = boundaryRef?.current;
    function pointerMoveListener() {
      rerender({});
    }

    boundaryElement?.addEventListener('pointermove', pointerMoveListener);
    return () => {
      boundaryElement?.removeEventListener('pointermove', pointerMoveListener);
    };
  }, [boundaryRef, isOpen]);
}

const Popover = withStyles({
  arrow: {
    color: Colors.GREY_0,
  },
  tooltip: {
    maxWidth: 'none',
    width: 'fit-content',
    backgroundColor: Colors.GREY_0,
    filter: `drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2)) drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12))`,
    padding: 0,
  },
})(MuiTooltip);

const useStyles = makeStylesHook(({ spacing, typography, palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(5),
    margin: 0,
    padding: spacing(5),
    width: 380,
    maxHeight: 280,
    overflow: 'hidden auto',
    scrollbarGutter: 'stable',
    color: palette.text.primary,
    ...typography.body2,
  },
  sectionHeader: {
    display: 'block',
    paddingBottom: spacing(3),
    color: Colors.INFO_DARK,
    fontWeight: 400,
  },
  noGutters: {
    margin: 0,
    padding: 0,
  },
  invalidParamsHeader: {
    padding: 0,
    margin: spacing(4, 0, 0),
  },
}));
