import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';
import MuiCardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { QUERY_XLSX_LIQUID_POLICIES } from 'client/app/api/gql/queries';
import useXlsxFileUpload from 'client/app/apps/policy-library/components/useXlsxFileUpload';
import XlsxFileError from 'client/app/apps/policy-library/components/XlsxFileError';
import { XlsxLiquidPolicy } from 'client/app/gql';
import { pluralize } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import LinearProgress from 'common/ui/components/LinearProgress';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import CustomLiquidPolicyIcon from 'common/ui/icons/CustomLiquidPolicyIcon';

type Props = {
  base64: string | undefined;
  onClose: () => void;
};

export default function PolicyUploadDialog({ base64, onClose }: Props) {
  const open = Boolean(base64);

  const { loading, uploadPoliciesXlsx } = useXlsxFileUpload({ onSuccess: onClose });
  const [policiesToUpload, setPoliciesToUpload] = useState<
    XlsxLiquidPolicy[] | undefined
  >();

  return (
    <Dialog open={open} fullWidth maxWidth="lg" onClose={onClose}>
      <DialogTitle>
        <Typography variant="h2">Upload liquid policies from file</Typography>{' '}
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!base64 ? (
          <Typography variant="h4" textAlign="center">
            File not found.
          </Typography>
        ) : (
          <XlsxPolicies base64={base64} onParse={setPoliciesToUpload} />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onClose}>
          <Typography variant="button" fontSize={14}>
            Cancel
          </Typography>
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={async () => {
            if (base64) {
              await uploadPoliciesXlsx(base64);
            }
          }}
          disabled={loading || !policiesToUpload?.length}
        >
          <Typography variant="button" fontSize={14}>
            Add new policies
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const LIQUID_POLICY_NAME_REGEX = /^[A-Za-z0-9,_,-]+$/;

function validatePolicyName(policy: XlsxLiquidPolicy) {
  return LIQUID_POLICY_NAME_REGEX.test(policy.name);
}

function XlsxPolicies({
  base64,
  onParse,
}: {
  base64: string;
  onParse: (policies: XlsxLiquidPolicy[]) => void;
}) {
  const { showError } = useSnackbarManager();

  const { data, loading, error } = useQuery(QUERY_XLSX_LIQUID_POLICIES, {
    variables: { base64 },
    fetchPolicy: 'network-only',
    onCompleted({ xlsxLiquidPolicies }) {
      onParse(xlsxLiquidPolicies.filter(p => !p.exists));
    },
    onError(error) {
      showError(error.message);
    },
  });

  const newPolicies =
    data?.xlsxLiquidPolicies.filter(p => !p.exists).filter(validatePolicyName) ?? [];
  const existingPolicies = data?.xlsxLiquidPolicies.filter(p => p.exists) ?? [];
  const policiesWithInvalidName =
    data?.xlsxLiquidPolicies.filter(p => !validatePolicyName(p)) ?? [];
  const policyWithBlankRules = data?.xlsxLiquidPolicies.find(
    policy => policy.blankRuleCount > 0,
  );
  const invalidPolicies =
    existingPolicies.length > 0 || policiesWithInvalidName.length > 0;

  return loading ? (
    <LinearProgress />
  ) : error ? (
    <XlsxFileError />
  ) : !data?.xlsxLiquidPolicies.length ? (
    <XlsxFileError error="No liquid policies found." />
  ) : (
    <Stack gap={5} pt={5} px={8} pb={8}>
      {newPolicies.length > 0 && (
        <>
          <Typography variant="h3">
            The following policies can be added to the inventory:
          </Typography>
          <Stack direction="row" flexWrap="wrap" gap={5} pb={8}>
            {newPolicies.map(policy => (
              <XlsxPolicyCard key={policy.name} policy={policy} />
            ))}
          </Stack>
        </>
      )}
      {policyWithBlankRules && (
        <Alert severity="warning" color="warning">
          <Typography variant="body1">
            {getBlankRuleWarningCopy(policyWithBlankRules.blankRuleCount)}
          </Typography>
        </Alert>
      )}
      {invalidPolicies && (
        <>
          <Typography variant="h3" color="error">
            Some policies cannot be added to the inventory.
          </Typography>
          {existingPolicies.length > 0 && (
            <>
              <Typography variant="h6">
                The following policy names already exist in your inventory. Please rename
                these policies and upload again.
              </Typography>
              <Stack direction="row" flexWrap="wrap" gap={5} pb={8}>
                {existingPolicies.map(policy => (
                  <XlsxPolicyCard key={policy.name} policy={policy} hasErrors />
                ))}
              </Stack>
            </>
          )}
          {policiesWithInvalidName.length > 0 && (
            <>
              <Typography variant="h6">
                The following policy names contain invalid characters. You can only use
                letters, numbers, and underscores; other characters, such as spaces, are
                not allowed. Please rename these policies and upload again.
              </Typography>
              <Stack direction="row" flexWrap="wrap" gap={5} pb={8}>
                {policiesWithInvalidName.map(policy => (
                  <XlsxPolicyCard key={policy.name} policy={policy} hasErrors />
                ))}
              </Stack>
            </>
          )}
        </>
      )}
    </Stack>
  );
}

function XlsxPolicyCard({
  policy,
  hasErrors = false,
}: {
  policy: XlsxLiquidPolicy;
  hasErrors?: boolean;
}) {
  return (
    <Card key={policy.name} hasErrors={hasErrors}>
      <CardHeader
        avatar={<CustomLiquidPolicyIcon />}
        title={<Typography variant="body1">{policy.name}</Typography>}
      />
      <CardContent>
        <Typography variant="caption">{pluralize(policy.ruleCount, 'rule')}</Typography>
      </CardContent>
    </Card>
  );
}

function getBlankRuleWarningCopy(blankRuleCount: number) {
  const firstSentence = `${
    blankRuleCount > 1 ? `There are ${blankRuleCount} rules` : 'There is 1 rule'
  } with no liquid type name assigned in this file.`;

  const secondSentence = `${
    blankRuleCount > 1 ? 'These rules have been applied' : 'This rule has been applied'
  } to all the liquid policies above.`;

  const thirdSentence = `If you would like to remove ${
    blankRuleCount > 1 ? 'these rules' : 'this rule'
  } instead, please delete the respective ${
    blankRuleCount > 1 ? 'columns' : 'column'
  } from the file and re-upload.`;

  return [firstSentence, secondSentence, thirdSentence].join(' ');
}

const DialogTitle = styled(MuiDialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: Colors.GREY_5,
  borderBottom: `1px solid ${Colors.GREY_30}`,
});

const DialogContent = styled(MuiDialogContent)({
  height: 600,
  backgroundColor: Colors.GREY_5,
  padding: 0,
});

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  justifyContent: 'flex-end',
  gap: theme.spacing(3),
  padding: theme.spacing(3, 6, 5),
  backgroundColor: Colors.GREY_5,
}));

const Card = styled(MuiCard, {
  shouldForwardProp: prop => prop !== 'hasErrors',
})<{
  hasErrors: boolean;
}>(({ theme, hasErrors }) => ({
  width: 336,
  margin: 0,

  outline: hasErrors ? `1px solid ${theme.palette.error.main}` : 'none',

  cursor: 'default',
  userSelect: 'none',
}));
const CardHeader = styled(MuiCardHeader)(({ theme }) => ({
  padding: theme.spacing(6, 6, 3),
}));
const CardContent = styled(MuiCardContent)(() => ({
  paddingTop: 0,
  paddingLeft: 64,
}));
