import React, { useCallback } from 'react';

import { useMutation } from '@apollo/client';
import SendRoundedIcon from '@mui/icons-material/SendRounded';

import { MUTATION_SHARE_WORKFLOW_WITH_ORG } from 'client/app/api/gql/mutations';
import ShareWithOrganisationsDialog from 'client/app/apps/workflow-builder/dialogs/ShareWithOrganisationsDialog';
import { SimulationForWorkflow } from 'client/app/apps/workflow-builder/panels/simulations/useSimulations';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import useDialog from 'common/ui/hooks/useDialog';

type Props = {
  simulation: SimulationForWorkflow;
  closeMenu: () => void;
};

export function ShareWorkflowItem({ closeMenu, simulation }: Props) {
  const snackbar = useSnackbarManager();
  const [shareWorkflowDialog, openShareWorkflowDialog] = useDialog(
    ShareWithOrganisationsDialog,
  );

  const [shareWorkflow] = useMutation(MUTATION_SHARE_WORKFLOW_WITH_ORG);
  const handleShareWorkflow = useCallback(async () => {
    closeMenu();
    if (simulation.status !== 'COMPLETED') {
      snackbar.showWarning('Cannot share a simulation that is not completed');
      return;
    }

    const orgsToShareWith = await openShareWorkflowDialog({
      workflowId: simulation.workflow.id,
    });
    if (orgsToShareWith) {
      const { errors } = await shareWorkflow({
        variables: {
          simulationId: simulation.id,
          destOrgName: orgsToShareWith.humanIdentifier,
          message: 'shared from Synthace',
        },
        // @ts-ignore This is valid option. TODO update types def when fix is available
        errorPolicy: 'all',
      });
      if (errors) {
        console.error(errors);
        snackbar.showError(`Failed to share with ${orgsToShareWith.name}`);
      } else {
        snackbar.showSuccess(`Shared with ${orgsToShareWith.name}`);
      }
    }
  }, [
    closeMenu,
    openShareWorkflowDialog,
    shareWorkflow,
    simulation.id,
    simulation.status,
    simulation.workflow.id,
    snackbar,
  ]);

  return (
    <>
      <MenuItemWithIcon
        key="send-workflow"
        icon={<SendRoundedIcon />}
        onClick={handleShareWorkflow}
        text="Send workflow"
      />
      {shareWorkflowDialog}
    </>
  );
}
