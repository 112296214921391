import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';

import RulesMeta from 'client/app/components/Parameters/Policy/assets/rulesMeta.json';

const mergedConsequences = {
  ...RulesMeta.consequences['General Properties and Tip Behaviour'],
  ...RulesMeta.consequences.Aspirate,
  ...RulesMeta.consequences.Dispense,
  ...RulesMeta.consequences['Post-Dispense Behaviour'],
  ...RulesMeta.consequences['Pre-Mix'],
  ...RulesMeta.consequences['Post-Mix'],
  ...RulesMeta.consequences.Other,
};
export const allConditions = reduce(
  // Sort alphabetically
  sortBy(keys(RulesMeta.conditions)),
  (result, key) => {
    const metaKey = key as RuleConditionKey;
    result[metaKey] = RulesMeta.conditions[metaKey];
    return result;
  },
  {} as RuleConditions,
);

export const allConsequences = reduce(
  // Do not sort, render them as they are: grouped by sections
  keys(mergedConsequences),
  (result, key) => {
    const metaKey = key as RuleConsequenceKey;
    result[metaKey] = mergedConsequences[metaKey];
    return result;
  },
  {} as RuleConsequences,
);

type RuleConditions = typeof RulesMeta.conditions;
type RuleConsequences = typeof mergedConsequences;
type RuleConditionKey = keyof RuleConditions;
type RuleConsequenceKey = keyof RuleConsequences;

export type RuleMetaType = 'condition' | 'consequence';
export type RuleMetaKey = RuleConditionKey | RuleConsequenceKey;

export function getRuleMeta(type: RuleMetaType, key: RuleMetaKey) {
  switch (type) {
    case 'condition':
      return allConditions[key as RuleConditionKey];
    case 'consequence':
      return allConsequences[key as RuleConsequenceKey];
    default:
      throw new Error(`No type: ${type}`);
  }
}

const ALLOWED_VOLUME_UNITS = ['pl', 'nl', 'ul', 'ml', 'l'];
const ALLOWED_TIME_UNITS = ['ns', 'us', 'ms', 's', 'm', 'h'];
const ALLOWED_FLOW_RATE_UNITS = ALLOWED_VOLUME_UNITS.flatMap(l =>
  ALLOWED_TIME_UNITS.map(t => `${l}/${t}`),
);
const AllowedUnits: Record<string, string[]> = {
  channel_max_flow_rate: ALLOWED_FLOW_RATE_UNITS,
  channel_min_flow_rate: ALLOWED_FLOW_RATE_UNITS,
  channel_max_volume: ALLOWED_VOLUME_UNITS,
  channel_min_volume: ALLOWED_VOLUME_UNITS,
  destination_final_volume: ALLOWED_VOLUME_UNITS,
  destination_initial_volume: ALLOWED_VOLUME_UNITS,
  destination_maxvolume: ALLOWED_VOLUME_UNITS,
  destination_residual: ALLOWED_VOLUME_UNITS,
  source_final_volume: ALLOWED_VOLUME_UNITS,
  source_initial_volume: ALLOWED_VOLUME_UNITS,
  source_max_volume: ALLOWED_VOLUME_UNITS,
  source_residual: ALLOWED_VOLUME_UNITS,
  transfer_volume: ALLOWED_VOLUME_UNITS,
};
/**
 * We need to validate units for condition expression since this
 * expression is a user input.
 *
 * NOTE: we do not need to validate rule consequences as by configuration
 * rule consequences have dropdown controls with predefined units for selection.
 *
 * @param condition liquid policy rule condition
 * @returns list of allowed units for a measurement expression
 */
export function getAllowedUnits(condition: string): string[] | undefined {
  return AllowedUnits[condition];
}
