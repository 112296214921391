import React, { MouseEventHandler, PropsWithChildren } from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import Panel from 'client/app/apps/workflow-builder/panels/Panel';

type Props = {
  title: string;
  onClose: () => void;
  onMouseEnterTitle?: MouseEventHandler;
  onMouseLeaveTitle?: MouseEventHandler;
} & PropsWithChildren;

export default function ProtocolInstancePanel({
  title,
  onClose,
  children,
  ...props
}: Props) {
  return (
    <StyledPanel
      title={title}
      panelContent="DOEElementInstance"
      onClose={onClose}
      {...props}
    >
      <Box p={3}>
        <ScrollContainer>{children}</ScrollContainer>
      </Box>
    </StyledPanel>
  );
}

const StyledPanel = styled(Panel)({
  gridArea: 'instancePanel',
  height: '100%',
  justifySelf: 'end',
  zIndex: 2,
});

const ScrollContainer = styled('div')(({ theme }) => ({
  overflowX: 'hidden',
  overflowY: 'auto',
  borderBottomRightRadius: 'inherit',
  borderBottomLeftRadius: 'inherit',
  marginBottom: theme.spacing(3),
  flex: '1 1 auto',
}));
