import React from 'react';

import { Route, Switch } from 'react-router-dom';

import CopyProtocol from 'client/app/apps/protocols/CopyProtocol';
import CreateProtocolFromWorkflow from 'client/app/apps/protocols/CreateProtocolFromWorkflow';
import EditProtocolScreen from 'client/app/apps/protocols/EditProtocolScreen';
import { protocolsRoutes } from 'client/app/lib/nav/actions';
import Screen404 from 'common/ui/components/Screen404';

export function ProtocolsRouter() {
  return (
    <Switch>
      <Route
        exact
        path={protocolsRoutes.createFromWorkflow.pathTemplate}
        render={route => (
          <CreateProtocolFromWorkflow
            id={route.match.params.id as WorkflowId}
            name={decodeURIComponent(route.match.params.name as string)}
          />
        )}
      />
      <Route
        exact
        path={protocolsRoutes.copyProtocol.pathTemplate}
        render={route => (
          <CopyProtocol
            id={route.match.params.id as ProtocolId}
            version={Number(route.match.params.version) as ProtocolVersion}
          />
        )}
      />
      <Route
        exact
        path={protocolsRoutes.editProtocol.pathTemplate}
        render={route => (
          <EditProtocolScreen
            id={route.match.params.id as ProtocolId}
            version={Number(route.match.params.version) as ProtocolVersion}
          />
        )}
      />
      <Route component={Screen404} />
    </Switch>
  );
}
