import React, { useState } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';

import Colors from 'common/ui/Colors';
import { MessagePreview } from 'common/ui/components/MessagePreview';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const DEFAULT_VALUE = `# Markdown Preview

Type some markdown on the left to see it previewed in the right.

We support standard markdown syntax such as,
* lists
* *italics*
* **bold**
* tables

As well as some extras like:

## Collapsibles

+++ Click to Reveal
which let you hide some text wrapped with \`+++\` behind a click
+++

## Cell merging in tables

See [here](https://www.npmjs.com/package/markdown-it-multimd-table) for info

Stage | Direct<br>Products | ATP Yields
----: | --------------: | ---------:
Glycolysis | 2 ATP ||
^^ | 2 NADH | 3--5 ATP |
Pyruvaye oxidation | 2 NADH | 5 ATP |
Citric acid cycle | 2 ATP ||
^^ | 6 NADH | 15 ATP |
^^ | 2 FADH2 | 3 ATP |
**30--32** ATP |||
[Net ATP yields per hexose]
`;

function MarkdownDevApp() {
  const classes = useStyles();

  const [markdown, setMarkdown] = useState(DEFAULT_VALUE);

  return (
    <div className={classes.previewScreen}>
      <Card className={classes.card}>
        <CardContent>
          <TextField
            className={classes.textEntry}
            fullWidth
            multiline
            value={markdown}
            onChange={evt => setMarkdown(evt.target.value)}
            variant="outlined"
          />
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <MessagePreview
            message={markdown}
            messageType="markdown"
            sx={{ flexGrow: 1 }}
          />
        </CardContent>
      </Card>
    </div>
  );
}

const useStyles = makeStylesHook({
  previewScreen: {
    backgroundColor: Colors.GREY_10,
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    overflow: 'auto',
  },
  card: {
    display: 'flex',
    height: 'fit-content',
    // Make this card as wide as the other cards
    flex: 1,
    minWidth: '260px',
    maxWidth: '800px',
  },
  textEntry: {
    flexGrow: 1,
  },
});

export default MarkdownDevApp;
