import { useCallback } from 'react';

import { usePostJSON } from 'client/app/lib/xhr';
import { downloadBase64EncodedFile } from 'common/lib/download';

const MIGRATE_ENDPOINT = '/web/migrate-policy';
const TEMPLATE_NAME = 'liquid_policies_template.xlsx';

/**
 * FIXME:
 * XLSX templates are aimed for backward compatibility with old way of handling
 * liquid policie.
 *
 * This should be removed when system is completely moved to the liquid policy library (one in the DB)
 */
export default function useDownloadTemplate() {
  const postJSON = usePostJSON();
  return useCallback(async () => {
    const migratePolicyResponse: { file: string } = await postJSON(MIGRATE_ENDPOINT, {
      body: { file: '' },
    });

    downloadBase64EncodedFile(migratePolicyResponse.file, TEMPLATE_NAME);
  }, [postJSON]);
}
