import React, { useCallback } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Tooltip from '@mui/material/Tooltip';

import { useDeleteSimulation } from 'client/app/api/SimulationsApi';
import {
  FINAL_SIMULATION_STATUSES,
  SimulationForWorkflow,
} from 'client/app/apps/workflow-builder/panels/simulations/useSimulations';
import { ScreenRegistry } from 'client/app/registry';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import useDialog from 'common/ui/hooks/useDialog';

type Props = {
  simulation: SimulationForWorkflow;
  workflowId: WorkflowId;
  closeMenu: () => void;
};

export function DeleteSimulation({ simulation, workflowId, closeMenu }: Props) {
  const deleteSimulation = useDeleteSimulation();
  const [confirmDeletionDialog, openConfirmDeletionDialog] =
    useDialog(ConfirmationDialog);

  const handleDeleteSimulation = useCallback(async () => {
    closeMenu();
    // We only want to show the confirmation dialog on successful simulations.
    const shouldConfirmDelete = simulation.status === 'COMPLETED';
    if (shouldConfirmDelete) {
      const isDeleteConfirmed = await openConfirmDeletionDialog({
        action: 'delete',
        isActionDestructive: true,
        object: 'simulation',
        specificObject: `${simulation.name}`,
      });
      if (!isDeleteConfirmed) {
        return;
      }
    }
    logEvent('delete-simulation', ScreenRegistry.WORKFLOW);
    // Note: This workflowId is different from the simulation's since that one is the
    // snapshot workflow id. We need the current workflow id.
    await deleteSimulation(simulation.id, workflowId);
  }, [
    closeMenu,
    deleteSimulation,
    openConfirmDeletionDialog,
    simulation.id,
    simulation.name,
    simulation.status,
    workflowId,
  ]);

  // Only delete a simulation if it does not have an execution, is in a final state, and has not been shared.
  const canDelete =
    FINAL_SIMULATION_STATUSES.includes(simulation.status) &&
    !simulation.execution?.id &&
    !simulation.isSharedExternally;
  return (
    <>
      <Tooltip title={!canDelete ? 'Simulation Shared' : ''}>
        <div>
          <MenuItemWithIcon
            onClick={handleDeleteSimulation}
            icon={<DeleteIcon />}
            text="Delete Simulation"
            disabled={!canDelete}
          />
        </div>
      </Tooltip>
      {confirmDeletionDialog}
    </>
  );
}
