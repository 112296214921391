import React, { useContext, useEffect, useMemo, useState } from 'react';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useProtocolContext } from 'client/app/apps/protocols/context/ProtocolProvider';
import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider';
import { useWorkflowContext } from 'client/app/apps/protocols/context/WorkflowProvider';
import ProtocolElementInputInstancePanel from 'client/app/apps/protocols/ProtocolInstancePanel/ElementInputPanel';
import ProtocolElementOutputInstancePanel from 'client/app/apps/protocols/ProtocolInstancePanel/ElementOutputPanel';
import { ResizableSidePanel } from 'client/app/apps/protocols/ResizableSidePanel';
import InputStepContents from 'client/app/apps/protocols/StepCard/InputStepContents';
import OutputStepContents from 'client/app/apps/protocols/StepCard/OutputStepContents';
import StepCard from 'client/app/apps/protocols/StepCard/StepCard';
import WorkflowPreview from 'client/app/components/ExampleWorkflows/WorkflowPreview';
import { ParameterStateContext } from 'client/app/lib/rules/elementConfiguration/ParameterStateContext';
import { getElementDisplayName } from 'client/app/lib/workflow/elementConfigUtils';
import { ElementInstance } from 'common/types/bundle';
import Button from 'common/ui/components/Button';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';

enum ViewTab {
  INPUTS,
  OUTPUTS,
  DEVICES,
}

const MIN_TAB_WIDTH = '138px';
const TABS: TabsInfo<ViewTab> = [
  { value: ViewTab.INPUTS, label: 'Parameters' },
  { value: ViewTab.OUTPUTS, label: 'Outputs' },
];

export const DefineProtocol = () => {
  const [tabId, setTabId] = useState(ViewTab.INPUTS);
  const [selectedObjectIds, setSelectedObjects] = useState<string[]>([]);
  const {
    displayDescription,
    updateProtocol,
    conflictDialog: protocolConflictDialog,
  } = useProtocolContext();
  const {
    workflowSchema,
    protocolSteps,
    selectedStep,
    createStep,
    updateStepName,
    toggleStepInput,
    toggleStepOutput,
    deleteStep,
    deleteStepInput,
    deleteStepOutput,
    handleSelectStep,
  } = useStepsContext();
  const {
    id: workflowId,
    getElementInstance,
    handleUpdateSchema,
    conflictDialog: workflowConflictDialog,
  } = useWorkflowContext();
  const [elementInstance, setElementInstance] = useState<ElementInstance>();
  const elementInputs = useInputParameters(elementInstance);

  useEffect(() => {
    if (selectedObjectIds.length === 1) {
      const id = selectedObjectIds[0];
      const ei = getElementInstance(id);
      setElementInstance(ei);
    } else {
      setElementInstance(undefined);
    }
  }, [getElementInstance, selectedObjectIds]);

  useEffect(() => {
    // Workflow schema provides the functional aspect of a step, while protocol
    // steps provide the presentational aspect. So we need to update both. We
    // are safe to do this asynchronously since steps context is the single
    // source of truth and is independent of any entity mutations
    handleUpdateSchema(workflowSchema);
    updateProtocol({ protocol: { displayDescription, steps: protocolSteps } });
  }, [
    displayDescription,
    workflowSchema,
    protocolSteps,
    handleUpdateSchema,
    updateProtocol,
  ]);

  return (
    <Content>
      <ResizableSidePanel>
        <SidePanel>
          <Typography variant="h2">Define Protocol</Typography>
          <ViewTabs
            tabsInfo={TABS}
            activeTab={tabId}
            minimumTabWidth={MIN_TAB_WIDTH}
            onChangeTab={setTabId}
          />
          {protocolSteps.map(step => (
            <StepCard
              key={step.id}
              name={step.displayName}
              active={step.id === selectedStep?.id}
              onActivate={() => handleSelectStep(step.id)}
              onChangeName={updateStepName(step)}
              onDelete={protocolSteps.length > 1 ? () => deleteStep(step.id) : undefined}
            >
              {tabId === ViewTab.INPUTS && (
                <InputStepContents step={step} onDelete={deleteStepInput(step)} />
              )}
              {tabId === ViewTab.OUTPUTS && (
                <OutputStepContents step={step} onDelete={deleteStepOutput(step)} />
              )}
            </StepCard>
          ))}
          <Button variant="primary" color="primary" onClick={createStep}>
            + Add a step
          </Button>
        </SidePanel>
      </ResizableSidePanel>
      <PreviewWrapper>
        {workflowId && (
          <WorkflowPreview
            workflowId={workflowId}
            setSelectedObjects={setSelectedObjects}
            selectedObjectIds={selectedObjectIds}
          />
        )}
      </PreviewWrapper>
      {selectedStep && elementInstance && (
        <InstancePanelWrapper>
          {tabId === ViewTab.INPUTS && (
            <ProtocolElementInputInstancePanel
              activeStepId={selectedStep.id}
              protocolSteps={protocolSteps}
              schema={workflowSchema}
              elementInstanceId={elementInstance.Id}
              elementInstanceName={getElementDisplayName(elementInstance.element, false)}
              inputs={elementInputs || []}
              onChange={toggleStepInput(selectedStep, elementInstance.Id)}
              onClose={() => setSelectedObjects([])}
            />
          )}
          {tabId === ViewTab.OUTPUTS && (
            <ProtocolElementOutputInstancePanel
              activeStepId={selectedStep.id}
              protocolSteps={protocolSteps}
              schema={workflowSchema}
              elementInstanceId={elementInstance.Id}
              elementInstanceName={getElementDisplayName(elementInstance.element, false)}
              outputs={elementInstance.element.outputs}
              onChange={toggleStepOutput(selectedStep, elementInstance.Id)}
              onClose={() => setSelectedObjects([])}
            />
          )}
        </InstancePanelWrapper>
      )}
      {workflowConflictDialog || protocolConflictDialog}
    </Content>
  );
};

const useInputParameters = (instance?: ElementInstance) => {
  const { getStateForParameter } = useContext(ParameterStateContext);
  const { getElementParameterValue } = useWorkflowContext();
  return useMemo(() => {
    return instance?.element.inputs
      .filter(({ name, configuration }) => {
        // parameter visibility can be dependent on element configuration rules
        const {
          // assume parameter is enabled if state not found
          isVisible: isVisibleByConfigRules = true,
          isEnabled = true,
        } = getStateForParameter(instance.name, name) || {};

        // while other options are simply a yes / no setting
        const {
          isBlockedFromSchema = false,
          isInternalOnly = false,
          isVisible = true, // assume isVisible if configuration is undefined
        } = configuration || {};
        return (
          isEnabled &&
          isVisible &&
          isVisibleByConfigRules &&
          !isBlockedFromSchema &&
          !isInternalOnly
        );
      })
      .map(parameter => ({
        parameter,
        value: getElementParameterValue(instance, parameter),
      }));
  }, [getElementParameterValue, getStateForParameter, instance]);
};

const Content = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
}));

const SidePanel = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  padding: theme.spacing(6, 4),
}));

const PreviewWrapper = styled('div')(() => ({
  flex: 1,
  overflow: 'hidden',
}));

const InstancePanelWrapper = styled('div')(() => ({
  position: 'absolute',
  right: 0,
  zIndex: 10,
  margin: '8px 8px 8px 8px',
  height: 'calc(100vh - 122px)',
  overflow: 'hidden',
}));

const ViewTabs = styled(Tabs)({
  '& button': {
    maxWidth: 'unset',
    flex: 1,
  },
}) as typeof Tabs;
