import React from 'react';

import { Route, Switch } from 'react-router-dom';

import CopyProtocolInstance from 'client/app/apps/protocols/CopyProtocolInstance';
import CreateProtocolInstance from 'client/app/apps/protocols/CreateProtocolInstance';
import EditProtocolInstanceScreen from 'client/app/apps/protocols/EditProtocolInstanceScreen';
import { protocolInstanceRoutes } from 'client/app/lib/nav/actions';
import Screen404 from 'common/ui/components/Screen404';

export function ProtocolInstanceRouter() {
  return (
    <Switch>
      <Route
        exact
        path={protocolInstanceRoutes.createProtocolInstance.pathTemplate}
        render={route => (
          <CreateProtocolInstance id={route.match.params.id as ProtocolId} version={0} /> // TODO: get protocol version from path
        )}
      />
      <Route
        exact
        path={protocolInstanceRoutes.copyProtocolInstance.pathTemplate}
        render={route => (
          <CopyProtocolInstance id={route.match.params.id as ProtocolInstanceId} />
        )}
      />
      <Route
        exact
        path={protocolInstanceRoutes.editProtocolInstance.pathTemplate}
        render={route => (
          <EditProtocolInstanceScreen id={route.match.params.id as ProtocolInstanceId} />
        )}
      />
      <Route component={Screen404} />
    </Switch>
  );
}
