import React, { useCallback } from 'react';

import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';

import { simulationRoutes } from 'client/app/lib/nav/actions';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import Tooltip from 'common/ui/components/Tooltip';
import { SimulationIcon } from 'common/ui/icons';

type Props = {
  simulationId?: SimulationId;
  tooltip: string;
};

export function ViewProtocolInstanceSimulationButton({ simulationId, tooltip }: Props) {
  const navigation = useNavigation();
  const handleViewSimulation = useCallback(async () => {
    simulationId &&
      navigation.navigate(simulationRoutes.openInSimulationDetails, {
        simulationId,
      });
  }, [navigation, simulationId]);

  return (
    <Tooltip title={tooltip}>
      <span>
        <Fab
          color="primary"
          onClick={handleViewSimulation}
          size="medium"
          disabled={!simulationId}
          variant="extended"
        >
          <StyledSimulateIcon /> View Simulation
        </Fab>
      </span>
    </Tooltip>
  );
}

const StyledSimulateIcon = styled(SimulationIcon)(({ theme }) => ({
  marginRight: theme.spacing(3),
}));
