import React from 'react';

import { useQuery } from '@apollo/client';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

import { QUERY_PROTOCOLS_BY_WORKFLOW_WITH_SIMID } from 'client/app/api/gql/queries';
import { useUpdateProtocol } from 'client/app/apps/protocols/api/ProtocolsAPI';
import { SimulationPart } from 'client/app/apps/workflow-builder/panels/simulations/useSimulations';
import { ProtocolsByWorkflowWithSimIDQuery } from 'client/app/gql';
import { simulationRoutes } from 'client/app/lib/nav/actions';
import { ScreenRegistry } from 'client/app/registry';
import { EditorType } from 'common/types/bundle';
import Button from 'common/ui/components/Button';
import RouteButton from 'common/ui/components/navigation/RouteButton';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import Tooltip from 'common/ui/components/Tooltip';

type Props = {
  simulation: SimulationPart;
  onViewError: () => void;
  source: EditorType;
  workflowId: WorkflowId;
};

export function SimulationAction({ simulation, onViewError, source, workflowId }: Props) {
  switch (simulation.status) {
    case 'COMPLETED':
      return source === EditorType.PROTOCOL ? (
        <SetProtocolSimulation simulation={simulation} workflowId={workflowId} />
      ) : (
        <ButtonWrapper>
          <RouteButton
            data-heap-tracking="builder-view-simulation-button"
            label="View Simulation"
            route={simulationRoutes.openInSimulationDetails}
            routeParam={{ simulationId: simulation.id }}
            logEventCategory={ScreenRegistry.WORKFLOW}
            size="small"
          />
        </ButtonWrapper>
      );
    case 'FAILED':
      return (
        <ButtonWrapper>
          <Button variant="tertiary" onClick={onViewError} size="small">
            View error
          </Button>
        </ButtonWrapper>
      );

    default:
      return null;
  }
}

function SetProtocolSimulation({
  simulation,
  workflowId,
}: {
  simulation: SimulationPart;
  workflowId: WorkflowId;
}) {
  const snackbarManager = useSnackbarManager();
  const query = useQuery(QUERY_PROTOCOLS_BY_WORKFLOW_WITH_SIMID, {
    variables: { workflowId },
    onError: err => snackbarManager.showError(`Error getting protocol: ${err.message}`),
  });

  return (
    <RenderQuery
      query={query}
      renderData={UpdateProtocolButton}
      additionalDataProps={{
        simulationId: simulation.id,
      }}
      loadingComponent={UpdateProtocolButtonSkeleton}
      renderNoData={() => null}
      emptyCondition={data => data.protocols.items.length === 0}
    />
  );
}

function UpdateProtocolButton({
  data,
  simulationId,
}: {
  data: ProtocolsByWorkflowWithSimIDQuery;
  simulationId: SimulationId;
}) {
  const protocol = data.protocols.items[0];
  const selectedSimulationId = protocol.exampleSimulation?.id;
  const simIsSelected = selectedSimulationId === simulationId;

  const { handleUpdateProtocol, loading } = useUpdateProtocol(
    protocol.id,
    protocol.version,
  );

  const updateProtocol = async () => {
    await handleUpdateProtocol(protocol.editVersion, {
      exampleSimulationId: simulationId,
    });
  };
  const title = loading
    ? ''
    : simIsSelected
    ? 'This simulation is being used as the example for the protocol'
    : 'Click to use this simulation as the example for the protocol';

  // span is needed because disabling the button would break the tooltip otherwise
  return (
    <Tooltip title={title}>
      <ButtonWrapper>
        <Button
          variant="tertiary"
          onClick={updateProtocol}
          size="small"
          disabled={simIsSelected || loading}
        >
          Update Protocol
        </Button>
      </ButtonWrapper>
    </Tooltip>
  );
}

const ButtonWrapper = styled('span')({
  gridArea: 'action',
  justifySelf: 'start',
});

const UpdateProtocolButtonSkeleton = styled(Skeleton)({
  gridArea: 'action',
  justifySelf: 'start',
  width: '50%',
  height: '100%',
});
