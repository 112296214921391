import React from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { StepResponseById } from 'client/app/apps/protocols/OutputStepResponse/useStepsReponse';
import { ValidationIndicator } from 'client/app/components/ValidationIndicator/ValidationIndicator';
import { pluralizeWord } from 'common/lib/format';

type Status = 'busy' | 'neutral' | 'ok';

type Props = {
  loadingStatus: Status;
  stepsResponse: StepResponseById;
  buttonPrefix: string;
  onClick: () => void;
};

export function OutputStepResponseValidationIndicator({
  loadingStatus,
  stepsResponse,
  buttonPrefix,
  onClick,
}: Props) {
  const status =
    loadingStatus !== 'ok'
      ? loadingStatus
      : Object.values(stepsResponse).some(v => v.errors.length > 0)
      ? 'error'
      : Object.values(stepsResponse).some(v => v.warnings.length > 0)
      ? 'warning'
      : 'ok';

  const hasErrorOrWarning = status === 'error' || status === 'warning';
  const errorOrWarning = status === 'error' ? 'error' : 'warning';

  const button = (
    <Button color={errorOrWarning} onClick={onClick}>
      {buttonPrefix} {pluralizeWord(2, errorOrWarning)}
    </Button>
  );

  return (
    <Stack direction="row">
      {hasErrorOrWarning ? button : null}
      <ValidationIndicator status={status} />
    </Stack>
  );
}
