import React from 'react';

import { styled } from '@mui/material/styles';

import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import { ProtocolInstancesQuery } from 'client/app/gql';
import { EntityCard } from 'common/ui/components/EntityCard';
import { ExampleWorkflowIcon } from 'common/ui/icons';

type Props = {
  data: ProtocolInstancesQuery;
  handleOnProtocolInstanceClick: (id: string) => void;
};

const ProtocolInstancesList = ({ data, handleOnProtocolInstanceClick }: Props) => {
  const protocolInstances = data.protocolInstances.items || [];

  return (
    <List>
      {protocolInstances.map(protocolInstance => (
        <StyledEntityCard
          key={protocolInstance.id}
          icon={<ExampleWorkflowIcon />}
          nameColumn={{ label: 'Name', value: protocolInstance.name }}
          authorColumn={{
            label: 'Author',
            value: protocolInstance.createdBy.displayName,
          }}
          dateColumn={{
            label: 'Name',
            value: new Date(protocolInstance.createdAt),
          }}
          interaction={{
            onClick: () => handleOnProtocolInstanceClick(protocolInstance.id),
          }}
        />
      ))}
    </List>
  );
};

const List = styled('div')(({ theme }) => ({
  ...experimentsStyles(theme).list,
}));

const StyledEntityCard = styled(EntityCard)(({ theme }) => ({
  '.EntityCardContent-nameColumn-label': {
    ...theme.typography.subtitle2,
  },
  '.EntityCardContent-nameColumn-value': {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
  },
}));

export default ProtocolInstancesList;
