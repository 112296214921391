import React, { useCallback, useContext } from 'react';

import { SimulationForWorkflow } from 'client/app/apps/workflow-builder/panels/simulations/useSimulations';
import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import useRequestHelpForSimulation from 'client/app/hooks/useRequestHelpForSimulation';
import { ScreenRegistry } from 'client/app/registry';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import { TalkToUsIcon } from 'common/ui/icons';

type Props = {
  simulation: SimulationForWorkflow;
  closeMenu: () => void;
};

export function RequestHelp({ simulation, closeMenu }: Props) {
  const { screenId } = useContext(ScreenContext);
  const { requestHelpDialog, handleRequestHelp } = useRequestHelpForSimulation(
    screenId!, // We should always have a screenId when we are rendering simulation card.
    simulation.id,
    simulation.name,
  );
  const closeMenuAndRequestHelp = useCallback(() => {
    logEvent('request-help', ScreenRegistry.WORKFLOW);
    closeMenu();
    void handleRequestHelp();
  }, [closeMenu, handleRequestHelp]); // Only show request help if the simulation is in a terminal state.

  const showRequestHelp = simulation.status !== 'RUNNING' && handleRequestHelp;

  return showRequestHelp ? (
    <>
      <MenuItemWithIcon
        onClick={closeMenuAndRequestHelp}
        icon={<TalkToUsIcon />}
        text="Request Help"
      />
      {requestHelpDialog}
    </>
  ) : null;
}
