import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { ProtocolInstancesDialog } from 'client/app/apps/protocols/RecentProtocolInstanceList/ProtocolInstancesDialog';
import { RecentProtocolInstancesListFooter } from 'client/app/apps/protocols/RecentProtocolInstanceList/RecentProtocolInstancesListFooter';
import {
  ENTITY_CARD_COLUMN_TEMPLATE,
  RecentProtocolInstancesListHeader,
} from 'client/app/apps/protocols/RecentProtocolInstanceList/RecentProtocolInstancesListHeader';
import { ProtocolInstancesQuery } from 'client/app/gql';
import { protocolInstanceRoutes } from 'client/app/lib/nav/actions';
import { formatDateTime } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import useDialog from 'common/ui/hooks/useDialog';

type Props = {
  data: ProtocolInstancesQuery;
  protocolId: ProtocolId;
};

const ProtocolInstancesList = ({ data, protocolId }: Props) => {
  const [protocolInstancesDialog, openProtocolInstancesDialog] = useDialog(
    ProtocolInstancesDialog,
  );
  const { navigate } = useNavigation();

  const instances = data.protocolInstances.items || [];

  const handleOnClick = (id: string) => {
    navigate(protocolInstanceRoutes.editProtocolInstance, {
      id,
    });
  };

  const handleDuplicateProtocol = async (protocolId: ProtocolId) => {
    await openProtocolInstancesDialog({ protocolId });
  };

  return (
    <Box>
      <RecentProtocolInstancesListHeader />
      {instances.slice(0, 5).map(instance => (
        <Content key={instance.id} onClick={() => handleOnClick(instance.id)}>
          <Typography variant="overline" sx={{ gridColumn: 'name' }}>
            {instance.name}
          </Typography>
          <Typography variant="overline" sx={{ gridColumn: 'author' }}>
            {instance.createdBy.displayName}
          </Typography>
          <Typography variant="overline" sx={{ gridColumn: 'date' }}>
            {formatDateTime(new Date(instance.createdAt))}
          </Typography>
        </Content>
      ))}
      {instances.length > 5 && (
        <RecentProtocolInstancesListFooter
          onClick={() => handleDuplicateProtocol(protocolId)}
        />
      )}
      {protocolInstancesDialog}
    </Box>
  );
};

const Content = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: ENTITY_CARD_COLUMN_TEMPLATE,
  gap: theme.spacing(5),
  height: '48px',
  overflow: 'hidden',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    background: Colors.BLUE_0,
  },
}));

export { ProtocolInstancesList };
