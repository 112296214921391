import React, { PropsWithChildren } from 'react';

import { styled } from '@mui/material/styles';

import Button from 'common/ui/components/Button';

type Props = {
  icon: React.ReactNode;
  onClick: () => void;
};

const DescriptionButton = ({ icon, onClick, children }: PropsWithChildren<Props>) => {
  return (
    <StyledButton endIcon={icon} variant="tertiary" onClick={onClick}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  color: theme.palette.text.primary,
}));

export { DescriptionButton };
