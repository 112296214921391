import React from 'react';

import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import Typography from '@mui/material/Typography';

import { TopBar } from 'client/app/components/TopBar';
import { ContentType } from 'client/app/gql';
import { simulationRoutes } from 'client/app/lib/nav/actions';
import getWorkflowPropsBySource from 'client/app/lib/workflow/getWorkflowPropsBySource';
import { ScreenRegistry } from 'client/app/registry';
import { EditorType } from 'common/types/bundle';
import Button from 'common/ui/components/Button';
import RouteButton from 'common/ui/components/navigation/RouteButton';
import { SimulationIcon } from 'common/ui/icons';

type Props = {
  authorName: string;
  simulationId?: SimulationId;
  handleCopyWorkflow: () => void;
  workflowContentSource: ContentType;
  parentWorkflowID: WorkflowId | null;
};

export function CopyWorkflowBar({
  authorName,
  simulationId,
  handleCopyWorkflow,
  workflowContentSource,
  parentWorkflowID,
}: Props) {
  const { route: workflowRoute } = getWorkflowPropsBySource(EditorType.WORKFLOW_EDITOR);

  const isExampleWorkflow = workflowContentSource === ContentType.EXAMPLE;
  const exampleCopy = `You are currently viewing an example workflow by ${authorName}. To edit or use this as a starting point for your own workflow, create a copy.`;
  const readOnlyCopy = `This is a read-only snapshot${
    authorName ? ` by ${authorName}` : ''
  }.`;

  return (
    <TopBar>
      <Typography variant="h6" color="textPrimary">
        {isExampleWorkflow ? exampleCopy : readOnlyCopy}
      </Typography>
      <Button
        data-heap-tracking="readonlybar-copy-workflow-button"
        onClick={handleCopyWorkflow}
        startIcon={<CopyIcon />}
        variant="primary"
        color="primary"
      >
        Make an editable copy
      </Button>
      {parentWorkflowID && !isExampleWorkflow && (
        <RouteButton
          data-heap-tracking="readonlybar-go-to-latest-button"
          route={workflowRoute}
          routeParam={{ workflowId: parentWorkflowID }}
          label="Go to latest"
          logEventCategory={ScreenRegistry.WORKFLOW}
          variant="contained"
          color="primary"
        />
      )}
      {simulationId && !isExampleWorkflow && (
        <RouteButton
          data-heap-tracking="readonlybar-go-to-simulation-button"
          route={simulationRoutes.openInSimulationDetails}
          routeParam={{ simulationId }}
          startIcon={<SimulationIcon />}
          label="Go to simulation"
          logEventCategory={ScreenRegistry.WORKFLOW}
          variant="contained"
          color="primary"
        />
      )}
    </TopBar>
  );
}
