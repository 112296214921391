import { useRef, useState } from 'react';

import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export default function useXlsxFileRead(onReadXlsx: (base64String: string) => void) {
  /** File input key allows to upload same file multiple times using HTMLInputElement */
  const [fileInputKey, setFileInputKey] = useState(0);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { showError } = useSnackbarManager();

  const selectFile = () => fileInputRef.current?.click();
  const openFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) {
      showError('XLSX file not found. Please, select a file with liquid policies.');
    } else if (event.target.files.length > 1) {
      showError('You can only upload one file with liquid policies.');
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          const base64String = reader.result;
          onReadXlsx(base64String);
          setFileInputKey(s => s + 1);
        } else {
          showError(
            'Could not parse file. Please make sure to select a valid XLSX file with liquid policies.',
          );
        }
      };
      const file = event.target.files[0];
      reader.readAsDataURL(file);
    }
  };

  return {
    fileInputKey,
    ref: (ref: HTMLInputElement) => (fileInputRef.current = ref),
    selectFile,
    openFile,
  };
}
