import React from 'react';

import ProtocolInstancePanel from 'client/app/apps/protocols/ProtocolInstancePanel/ProtocolInstancePanel';
import ProtocolItemList from 'client/app/apps/protocols/ProtocolInstancePanel/ProtocolItemList';
import { useElementNamePopover } from 'client/app/apps/workflow-builder/panels/element-instance-panel/hooks';
import { hasOutputVisualisation } from 'client/app/components/ElementPlumber/ElementOutputs/helpers';
import { Parameter } from 'common/types/bundle';
import { ProtocolStep } from 'common/types/Protocol';
import {
  byElementInstanceId,
  getElementParameterName,
  Schema,
} from 'common/types/schema';

type Props = {
  schema?: Schema;
  protocolSteps: ProtocolStep[];
  activeStepId: string;
  elementInstanceId: string;
  elementInstanceName: string;
  outputs: readonly Parameter[];
  onChange: (param: Parameter, checked: boolean) => void;
  onClose: () => void;
};

const ProtocolElementOutputInstancePanel = ({
  schema,
  protocolSteps,
  activeStepId,
  elementInstanceId,
  elementInstanceName,
  outputs,
  onChange,
  onClose,
}: Props) => {
  const { elementNamePopover, ...elementNamePopoverEvents } =
    useElementNamePopover(elementInstanceName);

  const selectedOutputs: Record<string, ProtocolStep[]> = {};
  const outputForElement =
    schema?.outputs?.filter(byElementInstanceId(elementInstanceId)) ?? [];
  protocolSteps.forEach(step => {
    step.outputs.forEach(protocolOutput => {
      const schemaOutput = outputForElement.find(v => v.id === protocolOutput.id);
      if (schemaOutput) {
        const pName = getElementParameterName(schemaOutput.path);
        if (!pName) {
          throw 'failed to get parameter name';
        }
        if (!selectedOutputs[pName]) {
          selectedOutputs[pName] = [];
        }
        selectedOutputs[pName].push(step);
      }
    });
  });

  return (
    <ProtocolInstancePanel
      title={elementInstanceName}
      onClose={onClose}
      {...elementNamePopoverEvents}
    >
      <ProtocolItemList
        elementId={elementInstanceId}
        parameters={outputs
          .filter(hasOutputVisualisation)
          .map(parameter => ({ parameter, value: undefined }))}
        selections={selectedOutputs}
        activeStepId={activeStepId}
        mode="output"
        onChange={(parameter, _, checked) => onChange(parameter, checked)}
      />
      {elementNamePopover}
    </ProtocolInstancePanel>
  );
};

export default ProtocolElementOutputInstancePanel;
