import React from 'react';

import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';

import { usePermissions } from 'client/app/components/Permissions';
import { UserRow } from 'client/app/components/UserTable/UserRow';
import { ArrayElement, availableRolesQuery, userRolesQuery } from 'client/app/gql';
import { UserProfileType } from 'client/app/hooks/useUserProfile';
import { scrollableContent } from 'common/ui/commonStyles';
import { Stack } from 'common/ui/components/Stack';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type UserRole = ArrayElement<userRolesQuery['users']>;
type AvailableRole = ArrayElement<availableRolesQuery['roles']>;

type UserTableProps = {
  userProfile: UserProfileType | undefined;
  orgUsers: readonly UserRole[] | undefined;
  availableRoles?: readonly AvailableRole[] | undefined;
};

export const UserTable = React.memo(
  ({ userProfile, orgUsers, availableRoles }: UserTableProps) => {
    const { currentUserHasPermission } = usePermissions();
    const classes = useStyles();
    const hasUpdatePermissions = currentUserHasPermission('update:users');

    const otherUsers = orgUsers?.filter(user => user.id !== userProfile?.id);

    return (
      <Stack spacing={4}>
        <div>
          <Typography variant="h3">
            Other Users ({otherUsers?.length.toString()})
          </Typography>
        </div>
        <div className={classes.root}>
          {otherUsers && (
            <Stack spacing={4} spaceAbove={0}>
              {otherUsers.map(user => (
                <UserRow
                  key={user.id}
                  user={user}
                  availableRoles={availableRoles}
                  isAdmin={hasUpdatePermissions}
                />
              ))}
            </Stack>
          )}
        </div>
      </Stack>
    );
  },
);

const useStyles = makeStylesHook(
  createStyles({
    root: {
      ...scrollableContent,
      height: '60vh',
      paddingRight: '15px',
      paddingLeft: '15px',
    },
  }),
);
