import React, { useCallback, useMemo } from 'react';

import { styled } from '@mui/material/styles';

import { useSimulationContext } from 'client/app/apps/protocols/context/SimulationProvider';
import FabWithFX from 'client/app/apps/workflow-builder/FabWithFX';
import useEntityConflictErrorDialog from 'client/app/hooks/useEntityConflictErrorDialog';
import { ElementContextMap } from 'common/types/bundle';
import { ErrorCodes } from 'common/types/errorCodes';
import { ProtocolStep } from 'common/types/Protocol';
import Tooltip from 'common/ui/components/Tooltip';
import { SimulationIcon } from 'common/ui/icons';

type Props = {
  loading: boolean;
  protocolSteps: ProtocolStep[];
  elementsContext: ElementContextMap;
};

export function SimulateProtocolInstanceButton({
  loading,
  protocolSteps,
  elementsContext,
}: Props) {
  const { handleCheckConflictError, conflictDialog } = useEntityConflictErrorDialog();
  const {
    onSimulate,
    isSimulating,
    simulateButtonTooltipTitle,
    shouldDisableSimulateButton,
  } = useSimulationContext();

  // stepElementsIds won't change for protocol instances
  const stepElementsIds = useMemo(() => {
    return new Set(
      protocolSteps.flatMap(step =>
        [...step.inputs, ...step.outputs].map(v => v.elementInstanceId),
      ),
    );
  }, [protocolSteps]);

  const anyErrorsInAllSteps = Object.entries(elementsContext)
    .filter(([elementId, _]) => stepElementsIds.has(elementId))
    .some(([_, context]) => context.errors.some(e => e.severity === 'error'));

  const handleOnSimulate = useCallback(async () => {
    try {
      await onSimulate();
    } catch (error) {
      await handleCheckConflictError(
        error,
        'protocol',
        ErrorCodes.PROTOCOL_INSTANCE_EDIT_CONFLICT,
      );
    }
  }, [handleCheckConflictError, onSimulate]);

  return (
    <>
      <Tooltip title={simulateButtonTooltipTitle}>
        <FabWithFX
          busy={isSimulating}
          color="secondary"
          onClick={handleOnSimulate}
          size="medium"
          disabled={loading || anyErrorsInAllSteps || shouldDisableSimulateButton}
          variant="extended"
        >
          <StyledSimulateIcon /> Simulate
        </FabWithFX>
      </Tooltip>
      {conflictDialog}
    </>
  );
}

const StyledSimulateIcon = styled(SimulationIcon)(({ theme }) => ({
  marginRight: theme.spacing(3),
}));
