import React from 'react';

import ExecuteButton from 'client/app/apps/simulation-details/ExecuteButton/ExecuteButton';
import useExecuteProtocol from 'client/app/apps/simulation-details/ExecuteButton/useExecuteProtocol';

type Props = {
  protocolInstanceId: ProtocolInstanceId;
  hasStartedExecution: boolean;
};

export default function ExecuteProtocolInstanceButton({
  protocolInstanceId,
  hasStartedExecution,
}: Props) {
  const [createExecution, { loading }] = useExecuteProtocol(protocolInstanceId);
  const tooltip = hasStartedExecution
    ? 'This execution has already started. If you want to run it in the lab again, please duplicate the protocol.'
    : '';

  return (
    <ExecuteButton
      tooltip={tooltip}
      disabled={hasStartedExecution || loading}
      onClick={createExecution}
    />
  );
}
