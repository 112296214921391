import React, { useCallback } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { QUERY_SIMULATION_CARD_POLL } from 'client/app/api/gql/queries';
import {
  A_TO_B_DURATION,
  cardItemExit,
  deletedCardAppear,
} from 'client/app/apps/workflow-builder/panels/simulations/deleteAnimation';
import DeletedSimulationCard from 'client/app/apps/workflow-builder/panels/simulations/DeletedSimulationCard';
import MoreActions from 'client/app/apps/workflow-builder/panels/simulations/simulation-card/MoreActions';
import { SimulationAction } from 'client/app/apps/workflow-builder/panels/simulations/simulation-card/SimulationAction';
import {
  isSimulationPlaceholder,
  SimulationForWorkflow,
  SimulationPart,
  SimulationPlaceholder,
} from 'client/app/apps/workflow-builder/panels/simulations/useSimulations';
import FavoriteStar from 'client/app/components/FavoriteStar';
import { SimulationStatusIndicatorWithTooltip } from 'client/app/components/SimulationStatusIndicator';
import { formatSimulationError } from 'client/app/lib/workflow/format';
import { ScreenRegistry } from 'client/app/registry';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { formatDateTime } from 'common/lib/format';
import { EditorType } from 'common/types/bundle';
import { ElementConfigurationSpec } from 'common/types/elementConfiguration';
import Colors from 'common/ui/Colors';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type SimulationCardItemProps = {
  simulation: SimulationForWorkflow | SimulationPlaceholder;
  handleSimulationError: (simulation: SimulationPart) => void;
  showInErrorState: boolean;
  elementConfigs?: Record<string, ElementConfigurationSpec | null>;
  workflowId: WorkflowId;
  pollingError?: string;
};

/**
 * Wrapper which switches to show a placeholder or the actual simulation card
 */
export default React.memo(function SimulationCardItem(props: SimulationCardItemProps) {
  const {
    simulation,
    handleSimulationError,
    showInErrorState,
    elementConfigs,
    workflowId,
    pollingError,
  } = props;
  const classes = useStyles();
  /*
   * If the simulation is not a placeholder, we should try to get the simulation data from Apollo.
   * We want to get the data from Apollo since the simulation doesn't necessarily have the latest data
   * E.g. if you have edited the name of the simulation, the Apollo cache will have the latest
   * simulation.
   *
   * If we are still trying to get the simulation data (e.g. it's not in the cache), we should
   * continue to show the placeholder loading card.
   *
   * If no data is retrieved, and the simulation is not a placeholder, we assume this has been deleted
   * by the user and show the DeletedSimulationCard
   */
  const { data, loading } = useQuery(QUERY_SIMULATION_CARD_POLL, {
    variables: { id: simulation.id },
    skip: isSimulationPlaceholder(simulation),
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  if (isSimulationPlaceholder(simulation)) {
    return <SimulationCardPlaceholder simulation={simulation} workflowId={workflowId} />;
  }
  const actualSimulation = data?.simulation;
  const isDeleted = !actualSimulation && !loading;

  return (
    <div>
      <TransitionGroup appear>
        {isDeleted && (
          <CSSTransition
            timeout={A_TO_B_DURATION}
            appear
            classNames={{
              enter: classes.deletedCardAppear,
              enterActive: classes.deletedCardAppearActive,
            }}
          >
            <DeletedSimulationCard />
          </CSSTransition>
        )}
        {actualSimulation && (
          <CSSTransition
            timeout={A_TO_B_DURATION}
            classNames={{
              exit: classes.cardItemTransitionExit,
              exitActive: classes.cardItemTransitionExitActive,
            }}
          >
            <SimulationCard
              simulation={actualSimulation}
              handleSimulationError={handleSimulationError}
              showInErrorState={showInErrorState}
              elementConfigs={elementConfigs}
              workflowId={workflowId}
              pollingError={pollingError}
            />
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
});

type SimulationCardPlaceholderProps = {
  simulation: SimulationForWorkflow | SimulationPlaceholder;
  workflowId: WorkflowId;
};

function CardHeader({
  simulation,
  workflowId,
  showViewSimulation,
}: {
  simulation: SimulationForWorkflow | SimulationPlaceholder;
  workflowId: WorkflowId;
  showViewSimulation?: boolean;
}) {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Typography variant="subtitle2" noWrap className="name">
        {simulation.name}
      </Typography>

      <Typography variant="caption" color="textSecondary" className="date">
        {formatDateTime(new Date(simulation.startedAt ?? Date.now()))}
      </Typography>

      <div className={classes.actions}>
        <FavoriteStar
          isFavoritedByCurrentUser={simulation.isFavoritedByCurrentUser ?? false}
          favoritedBy={simulation.favoritedBy ?? []}
          simulationId={simulation.id}
          size="small"
          associatedWorkflowId={workflowId}
          disabled={isSimulationPlaceholder(simulation)}
        />
        <MoreActions
          simulation={simulation}
          workflowId={workflowId}
          showViewSimulation={showViewSimulation}
        />
      </div>
    </div>
  );
}

function SimulationCardPlaceholder(props: SimulationCardPlaceholderProps) {
  const classes = useStyles();
  const { simulation } = props;

  return (
    <Paper variant="outlined" className={classes.card}>
      <CardHeader simulation={simulation} workflowId={props.workflowId} />
      <div className={classes.part}>
        <div className="status">
          <CircularProgress size={20} className="spinner" />
        </div>
      </div>
    </Paper>
  );
}

type SimulationCardProps = {
  simulation: SimulationForWorkflow;
  handleSimulationError: (simulation: SimulationPart) => void;
  showInErrorState: boolean;
  elementConfigs?: Record<string, ElementConfigurationSpec | null>;
  workflowId: WorkflowId;
  pollingError?: string;
};

function SimulationPartRow({
  simulation,
  onShowError,
  elementConfigs,
  source,
  workflowId,
}: {
  simulation: SimulationPart;
  onShowError: (simulation: SimulationPart) => void;
  elementConfigs?: Record<string, ElementConfigurationSpec | null>;
  source: EditorType;
  workflowId: WorkflowId;
}) {
  const classes = useStyles();

  const errors = simulation.errors?.map(error => {
    return formatSimulationError(error, elementConfigs);
  });

  const isPartOfSeries = simulation.simulationSeriesPart !== null;

  return (
    <li className={cx(classes.part, { [classes.multiPart]: isPartOfSeries })}>
      {isPartOfSeries && (
        <Typography className={classes.partNum} variant="caption">
          Part {simulation.simulationSeriesPart ?? 1}
        </Typography>
      )}
      <SimulationAction
        simulation={simulation}
        onViewError={() => onShowError(simulation)}
        source={source}
        workflowId={workflowId}
      />
      <SimulationStatusIndicatorWithTooltip
        isPolling
        status={simulation.status}
        containerClassName="status"
      />

      {errors.length > 0 ? (
        <div className={cx('errors', classes.errorContainer)}>
          {errors.map((error, index) => (
            <div key={`${index}`}>
              <Typography variant="caption" color="textSecondary">
                {/* We have some legacy errors where the error content is stored in the error.details, rather than
              the error.message - so if we have an empty string as error.message, use the error details for the content. */}
                {error.message || error.details}
              </Typography>
            </div>
          ))}
        </div>
      ) : null}
    </li>
  );
}

/**
 * This simulation card is one that we have an actual simulation id for. */
function SimulationCard(props: SimulationCardProps) {
  const classes = useStyles();
  const {
    simulation,
    handleSimulationError,
    showInErrorState,
    elementConfigs,
    workflowId,
    pollingError,
  } = props;

  const source = useWorkflowBuilderSelector(state => state.source);
  const isProtocol = source === EditorType.PROTOCOL;

  const handleViewErrorPanel = useCallback(
    (simulation: SimulationPart) => {
      handleSimulationError(simulation);
      logEvent('view-error-panel', ScreenRegistry.WORKFLOW);
    },
    [handleSimulationError],
  );

  return (
    <Paper
      variant="outlined"
      className={cx(classes.card, {
        [classes.erroredCard]: showInErrorState,
      })}
    >
      <CardHeader
        simulation={simulation}
        workflowId={workflowId}
        showViewSimulation={isProtocol}
      />

      {pollingError ? (
        <Typography variant="caption" color="textSecondary">
          We are unable to get the latest status for this simulation. Please refresh the
          page to try again or contact support if this issue continues.
        </Typography>
      ) : null}

      <ol className={classes.partsList}>
        <SimulationPartRow
          simulation={simulation}
          onShowError={handleViewErrorPanel}
          elementConfigs={elementConfigs}
          source={source}
          workflowId={workflowId}
        />

        {simulation?.simulationSeriesSiblings?.map(part =>
          part ? (
            <SimulationPartRow
              key={part.id}
              simulation={part}
              onShowError={handleViewErrorPanel}
              elementConfigs={elementConfigs}
              source={source}
              workflowId={workflowId}
            />
          ) : null,
        )}
      </ol>
    </Paper>
  );
}

const useStyles = makeStylesHook(({ spacing, palette }) => ({
  actions: {
    gridArea: 'actions',
    display: 'flex',
  },
  card: {
    border: `1px solid ${Colors.GREY_30}`,
    borderRadius: '4px',
    padding: spacing(4, 3, 3, 4),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(3),
  },
  header: {
    display: 'grid',
    gridTemplate: `
      "date actions" auto
      "name actions" auto
      / 1fr auto`,
    gap: spacing(3),

    '& > .name': {
      gridArea: 'name',
    },

    '& > .date': {
      gridArea: 'date',
    },
  },
  partsList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: '6px',
    padding: 0,
    margin: 0,
    listStyleType: 'none',
  },
  part: {
    display: 'grid',
    alignItems: 'center',
    gridTemplate: '"errors errors" auto "action status" auto / 1fr auto',
    columnGap: spacing(2),
    paddingRight: spacing(2),

    '& > .action': {
      gridArea: 'action',
      justifySelf: 'start',
    },

    '& > .status': {
      gridArea: 'status',
      margin: spacing(2, 1),
      display: 'flex',

      '& > .spinner': {
        margin: spacing(1),
      },
    },

    '& > .errors': {
      gridArea: 'errors',
      marginBottom: spacing(2),
    },
  },
  multiPart: {
    gridTemplate: '"num action status" auto "errors errors errors" auto / auto 1fr auto',
    '& > .errors': {
      marginTop: spacing(2),
      marginBottom: 0,
    },
  },
  partNum: {
    fontWeight: 'bold',
    background: Colors.BLUE_5,
    borderRadius: spacing(4),
    padding: spacing(2, 3),
    gridArea: 'num',
  },
  errorContainer: {
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  erroredCard: {
    border: `1px solid ${palette.error.main}`,
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  nameRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing(3),
  },
  simulationDelete: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
  starWithName: {
    display: 'flex',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  status: {
    margin: spacing(1),
  },
  ...cardItemExit,
  ...deletedCardAppear,
}));
