import React, { useEffect, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';

import { useCopyProtocolAndNavigate } from 'client/app/apps/protocols/api/ProtocolsAPI';
import UIErrorBox from 'client/app/components/UIErrorBox';

type Props = {
  id: ProtocolId;
  version: ProtocolVersion;
};

export default function CopyProtocol({ id, version }: Props) {
  const [error, setError] = useState<Error | null>(null);
  const { handleCopyProtocolAndNavigate, loading } = useCopyProtocolAndNavigate();

  useEffect(() => {
    const executeAsync = async () => {
      try {
        await handleCopyProtocolAndNavigate(id, version);
      } catch (e) {
        setError(e);
      }
    };
    void executeAsync();
  }, [handleCopyProtocolAndNavigate, id, version]);

  return (
    <>
      {loading && <LinearProgress />}
      {error && <UIErrorBox>Could not copy the protocol: {error.message}</UIErrorBox>}
    </>
  );
}
