import React, { useState } from 'react';

import ExpandMore from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { VisibilityIcon } from 'client/app/components/ElementPlumber/ElementOutputs/components/styles';
import useElementErrorParser from 'client/app/components/ElementPlumber/useElementErrorParser';
import { ElementError } from 'common/types/bundle';
import { MessagePreview } from 'common/ui/components/MessagePreview';

type Props = {
  errors: ElementError[];
};

export default function ErrorPreview({ errors }: Props) {
  const [open, setOpen] = useState(false);

  const parseError = useElementErrorParser();

  return (
    <Stack>
      <Stack direction="row" gap={3} padding={3} alignItems="center">
        <VisibilityIcon color="error" />
        <ErrorMessage variant="body2" whiteSpace="nowrap">
          No preview available due to workflow errors
        </ErrorMessage>
        <DropdownButton onClick={() => setOpen(flag => !flag)}>
          <DropdownIcon open={open} />
        </DropdownButton>
      </Stack>
      {open && (
        <>
          <Divider />
          <List>
            {errors.map((error, index) => (
              <ListItem key={index}>
                <ListItemText>
                  <MessagePreview
                    key={index}
                    message={parseError(error.message)}
                    messageType={error.messageType}
                  />
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Stack>
  );
}

const List = styled(MuiList)({
  overflow: 'auto',
  maxHeight: 200,
});

const ListItem = styled(MuiListItem)(({ theme }) => ({
  padding: theme.spacing(1, 4),
}));

const ListItemText = styled(MuiListItemText)({
  margin: 0,
});

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.dark,
}));

const DropdownButton = styled(IconButton)({
  padding: 0,
});

const DropdownIcon = styled(ExpandMore, { shouldForwardProp: prop => prop !== 'open' })<{
  open: boolean;
}>(({ theme, open }) => ({
  color: theme.palette.text.primary,
  transition: 'transform 100ms ease',
  transform: open ? 'rotate(180deg)' : 'unset',
}));
