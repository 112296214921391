import React, { PropsWithChildren } from 'react';

import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import EditableName, { Name } from 'client/app/apps/protocols/EditableName';
import DeleteButton from 'client/app/apps/protocols/StepCard/DeleteButton';
import doNothing from 'common/lib/doNothing';
import Colors from 'common/ui/Colors';

type Props = {
  name: string;
  onChangeName: (v: string) => void;
  active: boolean;
  /** called when an inactive step is activated */
  onActivate: () => void;
  onDelete?: () => void;
} & PropsWithChildren;

export default function StepCard({
  name,
  onChangeName,
  active,
  onActivate,
  onDelete,
  children,
}: Props) {
  return (
    <StyledCard active={active} onClick={active ? doNothing : onActivate}>
      <Stack gap={4}>
        {active ? (
          <Stack direction="row" justifyContent="space-between">
            <EditableName name={name} onSave={onChangeName} />
            {onDelete ? <DeleteButton onClick={onDelete} /> : null}
          </Stack>
        ) : (
          <Name>{name}</Name>
        )}
        <Collapse in={active}>{children}</Collapse>
      </Stack>
    </StyledCard>
  );
}

const StyledCard = styled(Paper, { shouldForwardProp: prop => prop !== 'active' })<{
  active?: boolean;
}>(({ active, theme: { spacing, palette } }) => ({
  border: active ? `1px solid ${palette.primary.main}` : 'unset',
  backgroundColor: active ? Colors.BLUE_0 : 'unset',
  '&:hover, &:focus': {
    backgroundColor: Colors.BLUE_0,
  },
  cursor: active ? 'unset' : 'pointer',
  borderRadius: '4px',
  padding: spacing(5),
  display: 'flex',
  flexDirection: 'column',
}));
