import React, { useState } from 'react';

import PolicyEditorDialog from 'client/app/apps/policy-library/components/EditorDialog';
import FabMenu from 'client/app/apps/policy-library/components/FabMenu';
import PolicyDialogStateProvider, {
  usePolicyDialogState,
} from 'client/app/apps/policy-library/components/PolicyDialogStateContext';
import PreviewPolicyDialog from 'client/app/apps/policy-library/components/PreviewDialog';
import PolicyUploadDialog from 'client/app/apps/policy-library/components/UploadDialog';
import useDeletePolicy from 'client/app/apps/policy-library/components/useDeletePolicy.hook';
import useOpenPolicyEditor from 'client/app/apps/policy-library/components/useOpenPolicyEditor.hook';
import PolicyLibraryAppNew from 'client/app/apps/policy-library/PolicyLibraryApp.new';
import PolicyLibraryAppOld from 'client/app/apps/policy-library/PolicyLibraryApp.old';
import DetailsView from 'client/app/components/Parameters/Policy/components/DetailsView';
import { LiquidPolicy } from 'client/app/gql';
import { useFeatureToggle } from 'common/features/useFeatureToggle';

function PolicyLibraryScreen() {
  const isEnabledLiquidPolicyLibrary = useFeatureToggle('LIQUID_POLICY_LIBRARY');

  // Card menu actions
  const deleteLiquidPolicy = useDeletePolicy();
  const editLiquidPolicy = useOpenPolicyEditor('edit');
  const copyLiquidPolicy = useOpenPolicyEditor('create');

  // Create dialog state & handlers
  const [policy, setPolicy] = useState<LiquidPolicy | undefined>();
  const [_, dispatch] = usePolicyDialogState();
  const openCreateDialog = () => {
    dispatch({ type: 'open_dialog', payload: { mode: 'create' } });
  };
  const closeCreateDialog = () => setPolicy(undefined);

  // Upload dialog state & handlers
  const [fileBase64, setFileBase64] = useState<string | undefined>();
  const openUploadDialog = (fileData: string) => {
    if (fileData.includes('base64')) {
      // File data is in format: `data:{mime-type};base64,{base64-encoded-data}`
      const base64String = fileData.split('base64,').at(1);
      setFileBase64(base64String);
    }
  };
  const closeUploadDialog = () => setFileBase64(undefined);

  return isEnabledLiquidPolicyLibrary ? (
    <>
      <PolicyLibraryAppNew
        onViewDetails={setPolicy}
        onDelete={deleteLiquidPolicy}
        onEdit={editLiquidPolicy}
        onCopy={copyLiquidPolicy}
      />
      {policy && (
        <PreviewPolicyDialog policy={policy} onClose={closeCreateDialog}>
          <DetailsView policy={policy} />
        </PreviewPolicyDialog>
      )}
      <FabMenu onCreate={openCreateDialog} onReadXlsx={openUploadDialog} />
      <PolicyEditorDialog onReadXlsx={openUploadDialog} />
      <PolicyUploadDialog base64={fileBase64} onClose={closeUploadDialog} />
    </>
  ) : (
    <PolicyLibraryAppOld />
  );
}

export default function PolicyLibraryWithState() {
  return (
    <PolicyDialogStateProvider>
      <PolicyLibraryScreen />
    </PolicyDialogStateProvider>
  );
}
