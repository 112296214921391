import React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';

const EmptyProtocolInstanceList = () => {
  return (
    <NoResultsMessage variant="overline">
      No protocol instances found for this protocol
    </NoResultsMessage>
  );
};

const NoResultsMessage = styled(Typography)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '45px',
  textAlign: 'center',
  backgroundColor: Colors.GREY_20,
}));

export { EmptyProtocolInstanceList };
