import React from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

type Props = {
  onClick: () => void;
};

export default function DeleteButton({ onClick }: Props) {
  return (
    <IconButton onClick={onClick} size="small">
      <StyledClearIcon />
    </IconButton>
  );
}

const StyledClearIcon = styled(ClearIcon)(({ theme: { spacing, palette } }) => ({
  width: spacing(5),
  height: spacing(5),
  color: palette.error.main,
}));
