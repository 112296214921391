import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';

type Props = {
  onClick: () => void;
};

const RecentProtocolInstancesListFooter = ({ onClick }: Props) => {
  return (
    <Wrapper onClick={onClick}>
      <Typography variant="overline">+ View more instances</Typography>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  height: '48px',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: Colors.GREY_20,
  cursor: 'pointer',
  '&:hover': {
    background: Colors.GREY_30,
  },
}));

export { RecentProtocolInstancesListFooter };
